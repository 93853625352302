/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SettingsImport } from './routes/settings'
import { Route as RegistrationImport } from './routes/registration'
import { Route as RecoveryImport } from './routes/recovery'
import { Route as LoginImport } from './routes/login'
import { Route as HelpImport } from './routes/help'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as AuthUsageIndexImport } from './routes/_auth.usage/index'
import { Route as AuthSubscribersIndexImport } from './routes/_auth.subscribers/index'
import { Route as AuthPartnersIndexImport } from './routes/_auth.partners/index'
import { Route as AuthDevicesIndexImport } from './routes/_auth.devices/index'
import { Route as AuthCoverageMapIndexImport } from './routes/_auth.coverage-map/index'
import { Route as AuthAdministrationIndexImport } from './routes/_auth.administration/index'
import { Route as AuthAccountIndexImport } from './routes/_auth.account/index'
import { Route as AuthSubscribersCreateImport } from './routes/_auth.subscribers/create'
import { Route as AuthSubscribersSubscriberIdImport } from './routes/_auth.subscribers/$subscriberId'
import { Route as AuthPartnersCreateImport } from './routes/_auth.partners/create'
import { Route as AuthPartnersPartnerIdImport } from './routes/_auth.partners/$partnerId'
import { Route as AuthDevicesCreateImport } from './routes/_auth.devices/create'
import { Route as AuthDevicesBulkManagementImport } from './routes/_auth.devices/bulk-management'
import { Route as AuthDevicesUsernameImport } from './routes/_auth.devices/$username'
import { Route as AuthAdministrationUsersIndexImport } from './routes/_auth.administration/users/index'
import { Route as AuthAdministrationCertificatesIndexImport } from './routes/_auth.administration/certificates/index'
import { Route as AuthAdministrationAuthGroupsIndexImport } from './routes/_auth.administration/auth-groups/index'
import { Route as AuthAdministrationApikeysIndexImport } from './routes/_auth.administration/apikeys/index'
import { Route as AuthAdministrationUsersCreateImport } from './routes/_auth.administration/users/create'
import { Route as AuthAdministrationUsersUserIdImport } from './routes/_auth.administration/users/$userId'
import { Route as AuthAdministrationCertificatesCreateImport } from './routes/_auth.administration/certificates/create'
import { Route as AuthAdministrationCertificatesIdImport } from './routes/_auth.administration/certificates/$id'
import { Route as AuthAdministrationAuthGroupsCreateImport } from './routes/_auth.administration/auth-groups/create'
import { Route as AuthAdministrationAuthGroupsAuthGroupIdImport } from './routes/_auth.administration/auth-groups/$authGroupId'

// Create/Update Routes

const SettingsRoute = SettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any)

const RegistrationRoute = RegistrationImport.update({
  id: '/registration',
  path: '/registration',
  getParentRoute: () => rootRoute,
} as any)

const RecoveryRoute = RecoveryImport.update({
  id: '/recovery',
  path: '/recovery',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const HelpRoute = HelpImport.update({
  id: '/help',
  path: '/help',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthUsageIndexRoute = AuthUsageIndexImport.update({
  id: '/usage/',
  path: '/usage/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSubscribersIndexRoute = AuthSubscribersIndexImport.update({
  id: '/subscribers/',
  path: '/subscribers/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPartnersIndexRoute = AuthPartnersIndexImport.update({
  id: '/partners/',
  path: '/partners/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDevicesIndexRoute = AuthDevicesIndexImport.update({
  id: '/devices/',
  path: '/devices/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthCoverageMapIndexRoute = AuthCoverageMapIndexImport.update({
  id: '/coverage-map/',
  path: '/coverage-map/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAdministrationIndexRoute = AuthAdministrationIndexImport.update({
  id: '/administration/',
  path: '/administration/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAccountIndexRoute = AuthAccountIndexImport.update({
  id: '/account/',
  path: '/account/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSubscribersCreateRoute = AuthSubscribersCreateImport.update({
  id: '/subscribers/create',
  path: '/subscribers/create',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSubscribersSubscriberIdRoute =
  AuthSubscribersSubscriberIdImport.update({
    id: '/subscribers/$subscriberId',
    path: '/subscribers/$subscriberId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthPartnersCreateRoute = AuthPartnersCreateImport.update({
  id: '/partners/create',
  path: '/partners/create',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPartnersPartnerIdRoute = AuthPartnersPartnerIdImport.update({
  id: '/partners/$partnerId',
  path: '/partners/$partnerId',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDevicesCreateRoute = AuthDevicesCreateImport.update({
  id: '/devices/create',
  path: '/devices/create',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDevicesBulkManagementRoute = AuthDevicesBulkManagementImport.update({
  id: '/devices/bulk-management',
  path: '/devices/bulk-management',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDevicesUsernameRoute = AuthDevicesUsernameImport.update({
  id: '/devices/$username',
  path: '/devices/$username',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAdministrationUsersIndexRoute =
  AuthAdministrationUsersIndexImport.update({
    id: '/administration/users/',
    path: '/administration/users/',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthAdministrationCertificatesIndexRoute =
  AuthAdministrationCertificatesIndexImport.update({
    id: '/administration/certificates/',
    path: '/administration/certificates/',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthAdministrationAuthGroupsIndexRoute =
  AuthAdministrationAuthGroupsIndexImport.update({
    id: '/administration/auth-groups/',
    path: '/administration/auth-groups/',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthAdministrationApikeysIndexRoute =
  AuthAdministrationApikeysIndexImport.update({
    id: '/administration/apikeys/',
    path: '/administration/apikeys/',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthAdministrationUsersCreateRoute =
  AuthAdministrationUsersCreateImport.update({
    id: '/administration/users/create',
    path: '/administration/users/create',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthAdministrationUsersUserIdRoute =
  AuthAdministrationUsersUserIdImport.update({
    id: '/administration/users/$userId',
    path: '/administration/users/$userId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthAdministrationCertificatesCreateRoute =
  AuthAdministrationCertificatesCreateImport.update({
    id: '/administration/certificates/create',
    path: '/administration/certificates/create',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthAdministrationCertificatesIdRoute =
  AuthAdministrationCertificatesIdImport.update({
    id: '/administration/certificates/$id',
    path: '/administration/certificates/$id',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthAdministrationAuthGroupsCreateRoute =
  AuthAdministrationAuthGroupsCreateImport.update({
    id: '/administration/auth-groups/create',
    path: '/administration/auth-groups/create',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthAdministrationAuthGroupsAuthGroupIdRoute =
  AuthAdministrationAuthGroupsAuthGroupIdImport.update({
    id: '/administration/auth-groups/$authGroupId',
    path: '/administration/auth-groups/$authGroupId',
    getParentRoute: () => AuthRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/help': {
      id: '/help'
      path: '/help'
      fullPath: '/help'
      preLoaderRoute: typeof HelpImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/recovery': {
      id: '/recovery'
      path: '/recovery'
      fullPath: '/recovery'
      preLoaderRoute: typeof RecoveryImport
      parentRoute: typeof rootRoute
    }
    '/registration': {
      id: '/registration'
      path: '/registration'
      fullPath: '/registration'
      preLoaderRoute: typeof RegistrationImport
      parentRoute: typeof rootRoute
    }
    '/settings': {
      id: '/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsImport
      parentRoute: typeof rootRoute
    }
    '/_auth/devices/$username': {
      id: '/_auth/devices/$username'
      path: '/devices/$username'
      fullPath: '/devices/$username'
      preLoaderRoute: typeof AuthDevicesUsernameImport
      parentRoute: typeof AuthImport
    }
    '/_auth/devices/bulk-management': {
      id: '/_auth/devices/bulk-management'
      path: '/devices/bulk-management'
      fullPath: '/devices/bulk-management'
      preLoaderRoute: typeof AuthDevicesBulkManagementImport
      parentRoute: typeof AuthImport
    }
    '/_auth/devices/create': {
      id: '/_auth/devices/create'
      path: '/devices/create'
      fullPath: '/devices/create'
      preLoaderRoute: typeof AuthDevicesCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/partners/$partnerId': {
      id: '/_auth/partners/$partnerId'
      path: '/partners/$partnerId'
      fullPath: '/partners/$partnerId'
      preLoaderRoute: typeof AuthPartnersPartnerIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/partners/create': {
      id: '/_auth/partners/create'
      path: '/partners/create'
      fullPath: '/partners/create'
      preLoaderRoute: typeof AuthPartnersCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/subscribers/$subscriberId': {
      id: '/_auth/subscribers/$subscriberId'
      path: '/subscribers/$subscriberId'
      fullPath: '/subscribers/$subscriberId'
      preLoaderRoute: typeof AuthSubscribersSubscriberIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/subscribers/create': {
      id: '/_auth/subscribers/create'
      path: '/subscribers/create'
      fullPath: '/subscribers/create'
      preLoaderRoute: typeof AuthSubscribersCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/account/': {
      id: '/_auth/account/'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AuthAccountIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/administration/': {
      id: '/_auth/administration/'
      path: '/administration'
      fullPath: '/administration'
      preLoaderRoute: typeof AuthAdministrationIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/coverage-map/': {
      id: '/_auth/coverage-map/'
      path: '/coverage-map'
      fullPath: '/coverage-map'
      preLoaderRoute: typeof AuthCoverageMapIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/devices/': {
      id: '/_auth/devices/'
      path: '/devices'
      fullPath: '/devices'
      preLoaderRoute: typeof AuthDevicesIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/partners/': {
      id: '/_auth/partners/'
      path: '/partners'
      fullPath: '/partners'
      preLoaderRoute: typeof AuthPartnersIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/subscribers/': {
      id: '/_auth/subscribers/'
      path: '/subscribers'
      fullPath: '/subscribers'
      preLoaderRoute: typeof AuthSubscribersIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/usage/': {
      id: '/_auth/usage/'
      path: '/usage'
      fullPath: '/usage'
      preLoaderRoute: typeof AuthUsageIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/administration/auth-groups/$authGroupId': {
      id: '/_auth/administration/auth-groups/$authGroupId'
      path: '/administration/auth-groups/$authGroupId'
      fullPath: '/administration/auth-groups/$authGroupId'
      preLoaderRoute: typeof AuthAdministrationAuthGroupsAuthGroupIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/administration/auth-groups/create': {
      id: '/_auth/administration/auth-groups/create'
      path: '/administration/auth-groups/create'
      fullPath: '/administration/auth-groups/create'
      preLoaderRoute: typeof AuthAdministrationAuthGroupsCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/administration/certificates/$id': {
      id: '/_auth/administration/certificates/$id'
      path: '/administration/certificates/$id'
      fullPath: '/administration/certificates/$id'
      preLoaderRoute: typeof AuthAdministrationCertificatesIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/administration/certificates/create': {
      id: '/_auth/administration/certificates/create'
      path: '/administration/certificates/create'
      fullPath: '/administration/certificates/create'
      preLoaderRoute: typeof AuthAdministrationCertificatesCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/administration/users/$userId': {
      id: '/_auth/administration/users/$userId'
      path: '/administration/users/$userId'
      fullPath: '/administration/users/$userId'
      preLoaderRoute: typeof AuthAdministrationUsersUserIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/administration/users/create': {
      id: '/_auth/administration/users/create'
      path: '/administration/users/create'
      fullPath: '/administration/users/create'
      preLoaderRoute: typeof AuthAdministrationUsersCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/administration/apikeys/': {
      id: '/_auth/administration/apikeys/'
      path: '/administration/apikeys'
      fullPath: '/administration/apikeys'
      preLoaderRoute: typeof AuthAdministrationApikeysIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/administration/auth-groups/': {
      id: '/_auth/administration/auth-groups/'
      path: '/administration/auth-groups'
      fullPath: '/administration/auth-groups'
      preLoaderRoute: typeof AuthAdministrationAuthGroupsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/administration/certificates/': {
      id: '/_auth/administration/certificates/'
      path: '/administration/certificates'
      fullPath: '/administration/certificates'
      preLoaderRoute: typeof AuthAdministrationCertificatesIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/administration/users/': {
      id: '/_auth/administration/users/'
      path: '/administration/users'
      fullPath: '/administration/users'
      preLoaderRoute: typeof AuthAdministrationUsersIndexImport
      parentRoute: typeof AuthImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthDevicesUsernameRoute: typeof AuthDevicesUsernameRoute
  AuthDevicesBulkManagementRoute: typeof AuthDevicesBulkManagementRoute
  AuthDevicesCreateRoute: typeof AuthDevicesCreateRoute
  AuthPartnersPartnerIdRoute: typeof AuthPartnersPartnerIdRoute
  AuthPartnersCreateRoute: typeof AuthPartnersCreateRoute
  AuthSubscribersSubscriberIdRoute: typeof AuthSubscribersSubscriberIdRoute
  AuthSubscribersCreateRoute: typeof AuthSubscribersCreateRoute
  AuthAccountIndexRoute: typeof AuthAccountIndexRoute
  AuthAdministrationIndexRoute: typeof AuthAdministrationIndexRoute
  AuthCoverageMapIndexRoute: typeof AuthCoverageMapIndexRoute
  AuthDevicesIndexRoute: typeof AuthDevicesIndexRoute
  AuthPartnersIndexRoute: typeof AuthPartnersIndexRoute
  AuthSubscribersIndexRoute: typeof AuthSubscribersIndexRoute
  AuthUsageIndexRoute: typeof AuthUsageIndexRoute
  AuthAdministrationAuthGroupsAuthGroupIdRoute: typeof AuthAdministrationAuthGroupsAuthGroupIdRoute
  AuthAdministrationAuthGroupsCreateRoute: typeof AuthAdministrationAuthGroupsCreateRoute
  AuthAdministrationCertificatesIdRoute: typeof AuthAdministrationCertificatesIdRoute
  AuthAdministrationCertificatesCreateRoute: typeof AuthAdministrationCertificatesCreateRoute
  AuthAdministrationUsersUserIdRoute: typeof AuthAdministrationUsersUserIdRoute
  AuthAdministrationUsersCreateRoute: typeof AuthAdministrationUsersCreateRoute
  AuthAdministrationApikeysIndexRoute: typeof AuthAdministrationApikeysIndexRoute
  AuthAdministrationAuthGroupsIndexRoute: typeof AuthAdministrationAuthGroupsIndexRoute
  AuthAdministrationCertificatesIndexRoute: typeof AuthAdministrationCertificatesIndexRoute
  AuthAdministrationUsersIndexRoute: typeof AuthAdministrationUsersIndexRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthDevicesUsernameRoute: AuthDevicesUsernameRoute,
  AuthDevicesBulkManagementRoute: AuthDevicesBulkManagementRoute,
  AuthDevicesCreateRoute: AuthDevicesCreateRoute,
  AuthPartnersPartnerIdRoute: AuthPartnersPartnerIdRoute,
  AuthPartnersCreateRoute: AuthPartnersCreateRoute,
  AuthSubscribersSubscriberIdRoute: AuthSubscribersSubscriberIdRoute,
  AuthSubscribersCreateRoute: AuthSubscribersCreateRoute,
  AuthAccountIndexRoute: AuthAccountIndexRoute,
  AuthAdministrationIndexRoute: AuthAdministrationIndexRoute,
  AuthCoverageMapIndexRoute: AuthCoverageMapIndexRoute,
  AuthDevicesIndexRoute: AuthDevicesIndexRoute,
  AuthPartnersIndexRoute: AuthPartnersIndexRoute,
  AuthSubscribersIndexRoute: AuthSubscribersIndexRoute,
  AuthUsageIndexRoute: AuthUsageIndexRoute,
  AuthAdministrationAuthGroupsAuthGroupIdRoute:
    AuthAdministrationAuthGroupsAuthGroupIdRoute,
  AuthAdministrationAuthGroupsCreateRoute:
    AuthAdministrationAuthGroupsCreateRoute,
  AuthAdministrationCertificatesIdRoute: AuthAdministrationCertificatesIdRoute,
  AuthAdministrationCertificatesCreateRoute:
    AuthAdministrationCertificatesCreateRoute,
  AuthAdministrationUsersUserIdRoute: AuthAdministrationUsersUserIdRoute,
  AuthAdministrationUsersCreateRoute: AuthAdministrationUsersCreateRoute,
  AuthAdministrationApikeysIndexRoute: AuthAdministrationApikeysIndexRoute,
  AuthAdministrationAuthGroupsIndexRoute:
    AuthAdministrationAuthGroupsIndexRoute,
  AuthAdministrationCertificatesIndexRoute:
    AuthAdministrationCertificatesIndexRoute,
  AuthAdministrationUsersIndexRoute: AuthAdministrationUsersIndexRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/help': typeof HelpRoute
  '/login': typeof LoginRoute
  '/recovery': typeof RecoveryRoute
  '/registration': typeof RegistrationRoute
  '/settings': typeof SettingsRoute
  '/devices/$username': typeof AuthDevicesUsernameRoute
  '/devices/bulk-management': typeof AuthDevicesBulkManagementRoute
  '/devices/create': typeof AuthDevicesCreateRoute
  '/partners/$partnerId': typeof AuthPartnersPartnerIdRoute
  '/partners/create': typeof AuthPartnersCreateRoute
  '/subscribers/$subscriberId': typeof AuthSubscribersSubscriberIdRoute
  '/subscribers/create': typeof AuthSubscribersCreateRoute
  '/account': typeof AuthAccountIndexRoute
  '/administration': typeof AuthAdministrationIndexRoute
  '/coverage-map': typeof AuthCoverageMapIndexRoute
  '/devices': typeof AuthDevicesIndexRoute
  '/partners': typeof AuthPartnersIndexRoute
  '/subscribers': typeof AuthSubscribersIndexRoute
  '/usage': typeof AuthUsageIndexRoute
  '/administration/auth-groups/$authGroupId': typeof AuthAdministrationAuthGroupsAuthGroupIdRoute
  '/administration/auth-groups/create': typeof AuthAdministrationAuthGroupsCreateRoute
  '/administration/certificates/$id': typeof AuthAdministrationCertificatesIdRoute
  '/administration/certificates/create': typeof AuthAdministrationCertificatesCreateRoute
  '/administration/users/$userId': typeof AuthAdministrationUsersUserIdRoute
  '/administration/users/create': typeof AuthAdministrationUsersCreateRoute
  '/administration/apikeys': typeof AuthAdministrationApikeysIndexRoute
  '/administration/auth-groups': typeof AuthAdministrationAuthGroupsIndexRoute
  '/administration/certificates': typeof AuthAdministrationCertificatesIndexRoute
  '/administration/users': typeof AuthAdministrationUsersIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/help': typeof HelpRoute
  '/login': typeof LoginRoute
  '/recovery': typeof RecoveryRoute
  '/registration': typeof RegistrationRoute
  '/settings': typeof SettingsRoute
  '/devices/$username': typeof AuthDevicesUsernameRoute
  '/devices/bulk-management': typeof AuthDevicesBulkManagementRoute
  '/devices/create': typeof AuthDevicesCreateRoute
  '/partners/$partnerId': typeof AuthPartnersPartnerIdRoute
  '/partners/create': typeof AuthPartnersCreateRoute
  '/subscribers/$subscriberId': typeof AuthSubscribersSubscriberIdRoute
  '/subscribers/create': typeof AuthSubscribersCreateRoute
  '/account': typeof AuthAccountIndexRoute
  '/administration': typeof AuthAdministrationIndexRoute
  '/coverage-map': typeof AuthCoverageMapIndexRoute
  '/devices': typeof AuthDevicesIndexRoute
  '/partners': typeof AuthPartnersIndexRoute
  '/subscribers': typeof AuthSubscribersIndexRoute
  '/usage': typeof AuthUsageIndexRoute
  '/administration/auth-groups/$authGroupId': typeof AuthAdministrationAuthGroupsAuthGroupIdRoute
  '/administration/auth-groups/create': typeof AuthAdministrationAuthGroupsCreateRoute
  '/administration/certificates/$id': typeof AuthAdministrationCertificatesIdRoute
  '/administration/certificates/create': typeof AuthAdministrationCertificatesCreateRoute
  '/administration/users/$userId': typeof AuthAdministrationUsersUserIdRoute
  '/administration/users/create': typeof AuthAdministrationUsersCreateRoute
  '/administration/apikeys': typeof AuthAdministrationApikeysIndexRoute
  '/administration/auth-groups': typeof AuthAdministrationAuthGroupsIndexRoute
  '/administration/certificates': typeof AuthAdministrationCertificatesIndexRoute
  '/administration/users': typeof AuthAdministrationUsersIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRouteWithChildren
  '/help': typeof HelpRoute
  '/login': typeof LoginRoute
  '/recovery': typeof RecoveryRoute
  '/registration': typeof RegistrationRoute
  '/settings': typeof SettingsRoute
  '/_auth/devices/$username': typeof AuthDevicesUsernameRoute
  '/_auth/devices/bulk-management': typeof AuthDevicesBulkManagementRoute
  '/_auth/devices/create': typeof AuthDevicesCreateRoute
  '/_auth/partners/$partnerId': typeof AuthPartnersPartnerIdRoute
  '/_auth/partners/create': typeof AuthPartnersCreateRoute
  '/_auth/subscribers/$subscriberId': typeof AuthSubscribersSubscriberIdRoute
  '/_auth/subscribers/create': typeof AuthSubscribersCreateRoute
  '/_auth/account/': typeof AuthAccountIndexRoute
  '/_auth/administration/': typeof AuthAdministrationIndexRoute
  '/_auth/coverage-map/': typeof AuthCoverageMapIndexRoute
  '/_auth/devices/': typeof AuthDevicesIndexRoute
  '/_auth/partners/': typeof AuthPartnersIndexRoute
  '/_auth/subscribers/': typeof AuthSubscribersIndexRoute
  '/_auth/usage/': typeof AuthUsageIndexRoute
  '/_auth/administration/auth-groups/$authGroupId': typeof AuthAdministrationAuthGroupsAuthGroupIdRoute
  '/_auth/administration/auth-groups/create': typeof AuthAdministrationAuthGroupsCreateRoute
  '/_auth/administration/certificates/$id': typeof AuthAdministrationCertificatesIdRoute
  '/_auth/administration/certificates/create': typeof AuthAdministrationCertificatesCreateRoute
  '/_auth/administration/users/$userId': typeof AuthAdministrationUsersUserIdRoute
  '/_auth/administration/users/create': typeof AuthAdministrationUsersCreateRoute
  '/_auth/administration/apikeys/': typeof AuthAdministrationApikeysIndexRoute
  '/_auth/administration/auth-groups/': typeof AuthAdministrationAuthGroupsIndexRoute
  '/_auth/administration/certificates/': typeof AuthAdministrationCertificatesIndexRoute
  '/_auth/administration/users/': typeof AuthAdministrationUsersIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/help'
    | '/login'
    | '/recovery'
    | '/registration'
    | '/settings'
    | '/devices/$username'
    | '/devices/bulk-management'
    | '/devices/create'
    | '/partners/$partnerId'
    | '/partners/create'
    | '/subscribers/$subscriberId'
    | '/subscribers/create'
    | '/account'
    | '/administration'
    | '/coverage-map'
    | '/devices'
    | '/partners'
    | '/subscribers'
    | '/usage'
    | '/administration/auth-groups/$authGroupId'
    | '/administration/auth-groups/create'
    | '/administration/certificates/$id'
    | '/administration/certificates/create'
    | '/administration/users/$userId'
    | '/administration/users/create'
    | '/administration/apikeys'
    | '/administration/auth-groups'
    | '/administration/certificates'
    | '/administration/users'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/help'
    | '/login'
    | '/recovery'
    | '/registration'
    | '/settings'
    | '/devices/$username'
    | '/devices/bulk-management'
    | '/devices/create'
    | '/partners/$partnerId'
    | '/partners/create'
    | '/subscribers/$subscriberId'
    | '/subscribers/create'
    | '/account'
    | '/administration'
    | '/coverage-map'
    | '/devices'
    | '/partners'
    | '/subscribers'
    | '/usage'
    | '/administration/auth-groups/$authGroupId'
    | '/administration/auth-groups/create'
    | '/administration/certificates/$id'
    | '/administration/certificates/create'
    | '/administration/users/$userId'
    | '/administration/users/create'
    | '/administration/apikeys'
    | '/administration/auth-groups'
    | '/administration/certificates'
    | '/administration/users'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/help'
    | '/login'
    | '/recovery'
    | '/registration'
    | '/settings'
    | '/_auth/devices/$username'
    | '/_auth/devices/bulk-management'
    | '/_auth/devices/create'
    | '/_auth/partners/$partnerId'
    | '/_auth/partners/create'
    | '/_auth/subscribers/$subscriberId'
    | '/_auth/subscribers/create'
    | '/_auth/account/'
    | '/_auth/administration/'
    | '/_auth/coverage-map/'
    | '/_auth/devices/'
    | '/_auth/partners/'
    | '/_auth/subscribers/'
    | '/_auth/usage/'
    | '/_auth/administration/auth-groups/$authGroupId'
    | '/_auth/administration/auth-groups/create'
    | '/_auth/administration/certificates/$id'
    | '/_auth/administration/certificates/create'
    | '/_auth/administration/users/$userId'
    | '/_auth/administration/users/create'
    | '/_auth/administration/apikeys/'
    | '/_auth/administration/auth-groups/'
    | '/_auth/administration/certificates/'
    | '/_auth/administration/users/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRouteWithChildren
  HelpRoute: typeof HelpRoute
  LoginRoute: typeof LoginRoute
  RecoveryRoute: typeof RecoveryRoute
  RegistrationRoute: typeof RegistrationRoute
  SettingsRoute: typeof SettingsRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRouteWithChildren,
  HelpRoute: HelpRoute,
  LoginRoute: LoginRoute,
  RecoveryRoute: RecoveryRoute,
  RegistrationRoute: RegistrationRoute,
  SettingsRoute: SettingsRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/help",
        "/login",
        "/recovery",
        "/registration",
        "/settings"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/devices/$username",
        "/_auth/devices/bulk-management",
        "/_auth/devices/create",
        "/_auth/partners/$partnerId",
        "/_auth/partners/create",
        "/_auth/subscribers/$subscriberId",
        "/_auth/subscribers/create",
        "/_auth/account/",
        "/_auth/administration/",
        "/_auth/coverage-map/",
        "/_auth/devices/",
        "/_auth/partners/",
        "/_auth/subscribers/",
        "/_auth/usage/",
        "/_auth/administration/auth-groups/$authGroupId",
        "/_auth/administration/auth-groups/create",
        "/_auth/administration/certificates/$id",
        "/_auth/administration/certificates/create",
        "/_auth/administration/users/$userId",
        "/_auth/administration/users/create",
        "/_auth/administration/apikeys/",
        "/_auth/administration/auth-groups/",
        "/_auth/administration/certificates/",
        "/_auth/administration/users/"
      ]
    },
    "/help": {
      "filePath": "help.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/recovery": {
      "filePath": "recovery.tsx"
    },
    "/registration": {
      "filePath": "registration.tsx"
    },
    "/settings": {
      "filePath": "settings.tsx"
    },
    "/_auth/devices/$username": {
      "filePath": "_auth.devices/$username.tsx",
      "parent": "/_auth"
    },
    "/_auth/devices/bulk-management": {
      "filePath": "_auth.devices/bulk-management.tsx",
      "parent": "/_auth"
    },
    "/_auth/devices/create": {
      "filePath": "_auth.devices/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/partners/$partnerId": {
      "filePath": "_auth.partners/$partnerId.tsx",
      "parent": "/_auth"
    },
    "/_auth/partners/create": {
      "filePath": "_auth.partners/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/subscribers/$subscriberId": {
      "filePath": "_auth.subscribers/$subscriberId.tsx",
      "parent": "/_auth"
    },
    "/_auth/subscribers/create": {
      "filePath": "_auth.subscribers/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/account/": {
      "filePath": "_auth.account/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/administration/": {
      "filePath": "_auth.administration/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/coverage-map/": {
      "filePath": "_auth.coverage-map/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/devices/": {
      "filePath": "_auth.devices/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/partners/": {
      "filePath": "_auth.partners/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/subscribers/": {
      "filePath": "_auth.subscribers/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/usage/": {
      "filePath": "_auth.usage/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/administration/auth-groups/$authGroupId": {
      "filePath": "_auth.administration/auth-groups/$authGroupId.tsx",
      "parent": "/_auth"
    },
    "/_auth/administration/auth-groups/create": {
      "filePath": "_auth.administration/auth-groups/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/administration/certificates/$id": {
      "filePath": "_auth.administration/certificates/$id.tsx",
      "parent": "/_auth"
    },
    "/_auth/administration/certificates/create": {
      "filePath": "_auth.administration/certificates/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/administration/users/$userId": {
      "filePath": "_auth.administration/users/$userId.tsx",
      "parent": "/_auth"
    },
    "/_auth/administration/users/create": {
      "filePath": "_auth.administration/users/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/administration/apikeys/": {
      "filePath": "_auth.administration/apikeys/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/administration/auth-groups/": {
      "filePath": "_auth.administration/auth-groups/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/administration/certificates/": {
      "filePath": "_auth.administration/certificates/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/administration/users/": {
      "filePath": "_auth.administration/users/index.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
