import { createFileRoute } from "@tanstack/react-router"
import { useSearch } from "@tanstack/react-router"
import React from "react"

import AdminTabs from "@/components/AdminTabs"
import CertificatesTable from "@/components/tables/CertificatesTable"
import LoadingTable from "@/components/tables/LoadingTable"
import { useCertificates } from "@/hooks"

export const Route = createFileRoute("/_auth/administration/certificates/")({
  component: () => <CertificatesPage />,
  staticData: {
    title: "Administration: Certificate Management",
  },
})

const CertificatesPage = () => {
  const { name, subscriberId } = useSearch({ strict: false })
  const { data, isPending } = useCertificates(subscriberId, name)

  return (
    <>
      <AdminTabs />
      {isPending ? (
        <LoadingTable rows={3} columns={6} />
      ) : (
        <CertificatesTable
          certificates={data}
          linkTo="/administration/certificates/"
        />
      )}
    </>
  )
}
