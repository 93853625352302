import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import LoadingTable from "@/components/tables/LoadingTable"
import PartnersTable from "@/components/tables/PartnersTable"
import { useAuth } from "@/contexts/auth"
import { usePartners } from "@/hooks"
import { hasPermission } from "@/utils/rbac"

type PartnerURLQueryParams = {
  offset?: number
  limit?: number
  name?: string
}

export const Route = createFileRoute("/_auth/partners/")({
  validateSearch: (search: Record<string, unknown>): PartnerURLQueryParams => {
    return {
      offset: search.offset as number,
      limit: search.limit as number,
    }
  },
  component: () => <PartnersPage />,
  staticData: {
    title: "Partners",
  },
})

const PartnersPage = () => {
  const { metadataPublic } = useAuth()

  const { offset = 0, limit = 10, name } = Route.useSearch()

  if (!hasPermission(metadataPublic, "managePartners")) {
    return <div>Unauthorized</div>
  }

  const partnersQuery = usePartners(name)

  if (partnersQuery.isPending) {
    return <LoadingTable rows={5} columns={4} />
  }

  if (partnersQuery.error) {
    return <div>Error: {partnersQuery.error.message}</div>
  }

  return (
    <PartnersTable
      linkTo="/partners"
      partners={partnersQuery.data.slice(offset, offset + limit)}
      totalCount={partnersQuery.data.length}
      limit={limit}
      offset={offset}
      name={name}
    />
  )
}
