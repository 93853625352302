import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import JsonPrettyPrint from "@/components/JsonPrettyPrint"
import LoadingTable from "@/components/tables/LoadingTable"
import SubscribersTable from "@/components/tables/SubscribersTable"
import { useSubscribers } from "@/hooks"

type Pagination = {
  offset?: number
  limit?: number
  partnerId?: string
  name?: string
}

export const Route = createFileRoute("/_auth/subscribers/")({
  validateSearch: (search: Record<string, unknown>): Pagination => {
    return {
      offset: search.offset as number,
      limit: search.limit as number,
    }
  },
  component: () => <SubscribersPage />,
  staticData: {
    title: "Subscribers",
  },
})

const SubscribersPage = () => {
  const { offset = 0, limit = 10, name, partnerId } = Route.useSearch()
  const { data, isPending, error } = useSubscribers(name, partnerId)

  if (isPending) {
    return <LoadingTable rows={5} columns={4} />
  }

  if (error) {
    return <JsonPrettyPrint json={error} />
  }

  return (
    <SubscribersTable
      linkTo={Route.fullPath}
      limit={limit}
      offset={offset}
      subscribers={data.slice(offset, offset + limit)}
      totalCount={data.length}
    />
  )
}
