import { createFileRoute } from "@tanstack/react-router"
import * as React from "react"

import JsonPrettyPrint from "@/components/JsonPrettyPrint"
import SubscriberForm from "@/components/forms/SubscriberForm"
import { useSubscriber } from "@/hooks"

export const Route = createFileRoute("/_auth/subscribers/$subscriberId")({
  component: () => <SubscriberDetailPage />,
  staticData: {
    title: "Edit Subscriber",
  },
})

const SubscriberDetailPage = () => {
  const { subscriberId } = Route.useParams()
  const { data, isPending, error } = useSubscriber(subscriberId)

  if (isPending) {
    return <p>Loading...</p>
  }

  if (error) {
    return <JsonPrettyPrint json={error} />
  }

  return <SubscriberForm subscriber={data} create={false} />
}
