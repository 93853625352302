import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import AdminTabs from "@/components/AdminTabs"
import JsonPrettyPrint from "@/components/JsonPrettyPrint"
import CertificateForm from "@/components/forms/CertificateForm"
import { useCertificate } from "@/hooks"

export const Route = createFileRoute("/_auth/administration/certificates/$id")({
  component: () => <CertificateDetailPage />,
  staticData: {
    title: "Administration: Certificate Detail",
  },
})

const CertificateDetailPage = () => {
  const { id } = Route.useParams()
  const { data, isPending, error } = useCertificate(id)

  if (isPending) {
    return <p>Loading...</p>
  }

  if (error) {
    return (
      <>
        <AdminTabs />
        <JsonPrettyPrint json={error} />
      </>
    )
  }

  return (
    <>
      <AdminTabs />
      <CertificateForm cert={data!} create={false} />
    </>
  )
}

export default CertificateDetailPage
