import { Field, Input, Label } from "@headlessui/react"
import { useForm } from "@tanstack/react-form"
import { Link, createFileRoute, useRouterState } from "@tanstack/react-router"
import * as React from "react"
import { z } from "zod"

import Button from "@/components/Button"
import FieldInfo from "@/components/FieldInfo"
import SwiftLogo from "@/components/SwiftLogo"
import APIConfig from "@/services/config"
import { orySDK } from "@/services/ory"

export const Route = createFileRoute("/registration")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
    flow: z.string().optional().catch(""),
  }),
  component: () => <AccountRegistration />,
})

const AccountRegistration = () => {
  const isLoading = useRouterState({ select: (s) => s.isLoading })
  // const navigate = Route.useNavigate()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [flow, setFlow] = React.useState<any>()
  // const [_cookies, setCookie] = useCookies(['swift.user'])

  const search = Route.useSearch()

  const flowId = search.flow

  const isLoggingIn = isLoading || isSubmitting

  React.useEffect(() => {
    if (flow !== undefined) {
      // flow exists already
      return
    }

    // if ?flow is in the url, we fetch it
    if (flowId) {
      orySDK
        .getRegistrationFlow({ id: flowId })
        .then(({ data }) => {
          setFlow(data)
        })
        .catch((err) => {
          console.error("registration flow error", err)
          // If the flow is no longer valid, request a new flow
          if (err.response.status === 410) {
            window.location.replace(
              `${APIConfig.kratosApi}/self-service/recovery/browser`,
            )
          }
        })
      return
    }

    // otherwise we initialize it
    orySDK
      .createBrowserRegistrationFlow()
      .then(({ data }) => {
        setFlow(data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [flow])

  const form = useForm({
    defaultValues: {
      password: "",
    },
    onSubmit: async ({ value }) => {
      setIsSubmitting(true)

      try {
        if (!flow) {
          console.error("No registration flow")
          return
        }
        if (value.password === "") {
          return
        }

        orySDK
          .updateRegistrationFlow({
            flow: flow.id,
            updateRegistrationFlowBody: {
              method: "webauthn",
              traits: {
                first_name: "John",
                last_name: "Doe",
              },
              csrf_token: flow.ui.nodes[0].attributes.value,
            },
          })
          .catch((err) => {
            console.error("registration error", err)
          })
      } catch (err) {
        console.error(err)
      } finally {
        setIsSubmitting(false)
      }
    },
  })

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="flex items-center justify-center">
          <SwiftLogo />
        </div>
        <h2 className="mt-5 text-center text-2xl leading-9 tracking-tight text-gray-800">
          Account Recovery
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          className="space-y-6"
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            form.handleSubmit()
          }}
        >
          <form.Field
            name="password"
            children={(field) => {
              return (
                <>
                  <Field className="mb-5">
                    <Label
                      htmlFor={field.name}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </Label>
                    <Input
                      tabIndex={1}
                      id={field.name}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      type="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                    <FieldInfo field={field} />
                  </Field>
                </>
              )
            }}
          />

          <Button type="submit" color="blue">
            {isLoggingIn ? "Loading..." : "Submit"}
          </Button>

          <div className="text-center mt-5">
            <Link to="/login" className="text-blue-500 hover:text-blue-700">
              Back to login
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}
