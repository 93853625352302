import { createFileRoute } from "@tanstack/react-router"
import * as React from "react"

import AdminTabs from "@/components/AdminTabs"
import Button from "@/components/Button"
import JsonPrettyPrint from "@/components/JsonPrettyPrint"
import AuthGroupForm from "@/components/forms/AuthGroupForm"
import AuthGroupPasswordForm from "@/components/forms/AuthGroupPasswordForm"
import AuthGroupsPasswordsTable from "@/components/tables/AuthGroupPasswordsTable"
import { useAuthGroup, useAuthGroupPasswords } from "@/hooks"
import icons from "@/icons"
import { AuthorizationGroupPassword } from "@/services/partnerApi"

export const Route = createFileRoute(
  "/_auth/administration/auth-groups/$authGroupId",
)({
  component: () => <AuthGroupDetailPage />,
  staticData: {
    title: "Administration: Edit Authorization Group",
  },
})

type PasswordState = "none" | "create" | AuthorizationGroupPassword

const AuthGroupDetailPage = () => {
  const { authGroupId } = Route.useParams()
  const authGroup = useAuthGroup(authGroupId)
  const passwords = useAuthGroupPasswords(authGroupId)
  const [passwordState, setPasswordState] =
    React.useState<PasswordState>("none")

  if (authGroup.isPending || passwords.isPending) {
    return <p>Loading...</p>
  }

  if (authGroup.isError) {
    return <JsonPrettyPrint json={authGroup.error} />
  }

  if (passwords.isError) {
    return <JsonPrettyPrint json={passwords.error} />
  }

  return (
    <>
      <AdminTabs />
      <div className="bg-white p-4">
        <div className="mb-6">
          <h2 className="font-bold">Authorization Group</h2>
          <p className="text-sm">
            A group of NTRIP credentials that can access Skylark via a shared
            device prefix.
          </p>
        </div>
        <AuthGroupForm authGroup={authGroup.data} create={false} />
        <div className="grid grid-cols-3 gap-4 py-8 mt-4 border-t">
          <div>
            <h2 className="font-bold">Passwords</h2>
            <p className="text-sm">
              Passwords associated with Authorization Groups are shared across
              all devices with the common prefix.
            </p>
          </div>
          <div className="col-span-2">
            <AuthGroupsPasswordsTable
              authGroup={authGroup.data}
              passwords={passwords.data}
              onEdit={(agp) => setPasswordState(agp)}
            />
            <div className="flex items-center justify-end space-y-3 md:space-y-0 md:space-x-4 p-4">
              <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <Button
                  onClick={() => setPasswordState("create")}
                  disabled={passwordState !== "none"}
                  color="primary"
                >
                  <icons.Plus /> Create Password
                </Button>
              </div>
            </div>
          </div>
        </div>
        {passwordState === "create" ? (
          <AuthGroupPasswordForm
            authGroup={authGroup.data}
            authGroupPassword={undefined}
            create={true}
            onSuccess={() => setPasswordState("none")}
          />
        ) : passwordState != "none" ? (
          <AuthGroupPasswordForm
            authGroup={authGroup.data}
            authGroupPassword={passwordState}
            create={false}
            onSuccess={() => setPasswordState("none")}
          />
        ) : null}
      </div>
    </>
  )
}
