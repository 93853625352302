import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import BulkUpload from "@/components/forms/BulkUpload"

export const Route = createFileRoute("/_auth/devices/bulk-management")({
  component: () => BulkDevicesPage(),
  staticData: {
    title: "Devices: Bulk Management",
  },
})

const BulkDevicesPage = () => {
  return <BulkUpload />
}
