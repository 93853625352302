import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import Button from "@/components/Button"
import SideNav from "@/components/SideNav"
import TopNav from "@/components/TopNav"
import { useAuth } from "@/contexts/auth"
import config from "@/services/config"

export const Route = createFileRoute("/help")({
  beforeLoad: async ({ context }) => {
    const user = (await context.auth.checkSession()) as any
    return { user }
  },
  component: () => <HelpPage />,
})

const HelpPage = () => {
  const { metadataPublic } = useAuth()

  return (
    <div className="antialiased bg-gray-50">
      <SideNav />
      <main className="md:ml-64 h-auto p-4 mt-20">
        <TopNav pageTitle="Help" />

        <h1 className="text-1xl font-semibold mb-3 ml-1">User Guides</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {metadataPublic.role === "swift:admin" && (
            <div className="border border-slate-200 bg-white rounded-lg shadow-md p-4">
              <h2 className="text-xl font-bold mb-2">Swift Admin User Guide</h2>
              <p className="text-slate-600 leading-normal font-light">
                For Swift Employees and Administrators
              </p>
              <Button
                color="primary"
                className="mt-6"
                href="/docs/Swift-Administrators-Guide.pdf"
                target="_blank"
              >
                <span>Download</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="size-5 ml-2"
                >
                  <path d="M10.75 2.75a.75.75 0 0 0-1.5 0v8.614L6.295 8.235a.75.75 0 1 0-1.09 1.03l4.25 4.5a.75.75 0 0 0 1.09 0l4.25-4.5a.75.75 0 0 0-1.09-1.03l-2.955 3.129V2.75Z" />
                  <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
                </svg>
              </Button>
            </div>
          )}
          <div className="border border-slate-200 bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-bold mb-2">Partner Admin User Guide</h2>
            <p className="text-slate-600 leading-normal font-light">
              For Partner Administrators
            </p>
            <Button
              color="primary"
              className="mt-6"
              href="/docs/Partner-Administrators-Guide.pdf"
              target="_blank"
            >
              <span>Download</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="size-5 ml-2"
              >
                <path d="M10.75 2.75a.75.75 0 0 0-1.5 0v8.614L6.295 8.235a.75.75 0 1 0-1.09 1.03l4.25 4.5a.75.75 0 0 0 1.09 0l4.25-4.5a.75.75 0 0 0-1.09-1.03l-2.955 3.129V2.75Z" />
                <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
              </svg>
            </Button>
          </div>
          {/* <div className="border border-slate-200 bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-bold mb-2">
              Subscriber Admin User Guide
            </h2>
            <p className="text-slate-600 leading-normal font-light">
              Coming Soon!
            </p>
            <Button disabled color="primary" className="mt-6">
              <span>Download</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="size-5 ml-2"
              >
                <path d="M10.75 2.75a.75.75 0 0 0-1.5 0v8.614L6.295 8.235a.75.75 0 1 0-1.09 1.03l4.25 4.5a.75.75 0 0 0 1.09 0l4.25-4.5a.75.75 0 0 0-1.09-1.03l-2.955 3.129V2.75Z" />
                <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
              </svg>
            </Button>
          </div> */}
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0" />

        <h1 className="text-1xl font-semibold mb-3 ml-1">Resources</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="grid grid-rows-3 border border-slate-200 bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-bold mb-2">Partner API</h2>
            <p className="text-slate-600 leading-normal font-light mb-2">
              Manage Skylark resources through a REST API.
            </p>

            <div className="content-center mb-2">
              <Button
                href={config.apiDocs}
                target="_blank"
                color="primary"
                className=""
              >
                <span>View Documentation</span>
              </Button>
            </div>
          </div>

          <div className="grid grid-rows-3 border border-slate-200 bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-bold mb-2">Support Portal</h2>
            <p className="text-slate-600 leading-normal font-light mb-2">
              Get help with Swift Navigation products and services.
            </p>

            <div className="content-center mb-2">
              <Button
                href="https://support.swiftnav.com/support/home"
                target="_blank"
                color="primary"
              >
                <span>Go To Support Portal</span>
              </Button>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
