import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import JsonPrettyPrint from "@/components/JsonPrettyPrint"
import PartnerForm from "@/components/forms/PartnerForm"
import { useAuth } from "@/contexts/auth"
import { usePartner } from "@/hooks"
import { hasPermission } from "@/utils/rbac"

export const Route = createFileRoute("/_auth/partners/$partnerId")({
  component: () => <PartnerDetailPage />,
  staticData: {
    title: "Edit Partner",
  },
})

const PartnerDetailPage = () => {
  const { partnerId } = Route.useParams()
  const { metadataPublic } = useAuth()

  if (!hasPermission(metadataPublic, "managePartners")) {
    return <div>Unauthorized</div>
  }

  const { data: partner, isLoading, error } = usePartner(partnerId)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <JsonPrettyPrint json={error} />
  }

  return <PartnerForm partner={partner} create={false} />
}
