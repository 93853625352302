import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

import APIConfig from "./config"
import {
  SkylarkType,
  createAuthorizationGroupPasswordsClient,
  createAuthorizationGroupsClient,
  createDevicesClient,
  createSubscribersClient,
  createX509CertificatesClient,
} from "./generated/swiftnav/partners/v1/index.ts"

const partnerApi: AxiosInstance = axios.create({
  baseURL: `${APIConfig.partnerApi}/v1`,
  withCredentials: true,
})

// Add interceptors
partnerApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to login page or perform any other action for unauthorized access
      window.location.href = "/login"
    }
    return Promise.reject(error)
  },
)

const axiosHandler = async (request: {
  path: string
  method: string
  body: string | null
  signal?: AbortSignal
}) => {
  try {
    const config: AxiosRequestConfig = {
      url: request.path,
      method: request.method,
      data: request.body,
      signal: request.signal,
    }
    const response = await partnerApi(config)
    return response.data
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message)
      throw error
    }
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || error.response.statusText)
    }
    throw error
  }
}

// Wrapper function to create clients with abort signal support
const createClientWithAbortSignal = <T extends (...args: any[]) => any>(
  clientCreator: T,
): ((signal?: AbortSignal) => ReturnType<T>) => {
  return (signal?: AbortSignal) => {
    const client = clientCreator((req: any) => axiosHandler({ ...req, signal }))
    return client
  }
}

const createAuthorizationGroupPasswordsClientWithAbort =
  createClientWithAbortSignal(createAuthorizationGroupPasswordsClient)
const createAuthorizationGroupsClientWithAbort = createClientWithAbortSignal(
  createAuthorizationGroupsClient,
)
const createSubscribersClientWithAbort = createClientWithAbortSignal(
  createSubscribersClient,
)
const createDevicesClientWithAbort =
  createClientWithAbortSignal(createDevicesClient)
const createX509CertificatesClientWithAbort = createClientWithAbortSignal(
  createX509CertificatesClient,
)

export const AuthorizationGroupPasswords =
  createAuthorizationGroupPasswordsClientWithAbort()
export const AuthorizationGroups = createAuthorizationGroupsClientWithAbort()
export const Subscribers = createSubscribersClientWithAbort()
export const Devices = createDevicesClientWithAbort()
export const X509Certificates = createX509CertificatesClientWithAbort()

export * from "./generated/swiftnav/partners/v1/index.ts"

export const SkylarkTypes: SkylarkType[] = [
  "SKYLARK_CX",
  "SKYLARK_DX",
  "SKYLARK_NX_RTK",
]

export const skylarkTypeToName = (skylarkType: SkylarkType): string => {
  switch (skylarkType) {
    case "SKYLARK_UNSPECIFIED":
      return "Unspecified"
    case "SKYLARK_CX":
      return "Skylark Cx"
    case "SKYLARK_DX":
      return "Skylark Dx"
    case "SKYLARK_NX_RTK":
      return "Skylark Nx RTK"
  }
}

export function generatePassword(): string {
  // 32 characters trying to avoid ambiguous ones
  const alphabet = "0123456789abcdefghkmnpqrstuvwxyz"
  return Array.from(
    { length: 12 },
    () => alphabet[Math.floor(Math.random() * alphabet.length)],
  ).join("")
}
