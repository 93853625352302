import { FullStory } from "@fullstory/browser"
import {
  Outlet,
  createFileRoute,
  redirect,
  useChildMatches,
} from "@tanstack/react-router"
import * as React from "react"

import PageNotFound from "@/components/PageNotFound"
import SideNav from "@/components/SideNav"
import TopNav from "@/components/TopNav"
import Notifications from "@/components/toast/Notifications"

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ context, location }) => {
    const session = await context.auth.checkSession()
    if (!session?.active) {
      throw redirect({ to: "/login", search: { redirect: location.href } })
    } else if (session?.identity) {
      if (window.location.hostname !== "localhost") {
        FullStory("setIdentity", {
          uid: session.identity.id,
          properties: {
            displayName:
              session.identity.traits.name.first +
              " " +
              session.identity.traits.name.last,
            email: session.identity.traits.email,
          },
        })
      }
    }
    return { session }
  },
  component: () => <AuthLayout />,
  notFoundComponent: () => <PageNotFound />,
})

const AuthLayout = () => {
  const title = useChildMatches().find((m) => m.staticData.title)?.staticData
    .title

  return (
    <div className="antialiased bg-gray-50">
      <SideNav />
      <main className="md:ml-64 h-auto p-4 mt-20">
        {title && <TopNav pageTitle={title} />}
        <Outlet />
      </main>
      <Notifications />
    </div>
  )
}
