import { createFileRoute } from "@tanstack/react-router"
import React from "react"

export const Route = createFileRoute("/_auth/coverage-map/")({
  component: () => <RTKMap />,
  staticData: {
    title: "Nx RTK Coverage Map",
  },
})

const RTKMap: React.FC = () => (
  <iframe
    src="/station-coverage/rtk"
    title="RTK Map"
    style={{ width: "100%", height: "800px" }}
  />
)
