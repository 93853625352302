import type { UpdateIdentityBody } from "@ory/client"
import axios from "axios"
import type { AxiosResponse } from "axios"

import APIConfig from "./config"
import type {
  Partner,
  PartnerSummary,
  User,
  UserKratosIdentity,
  createUsageReportInput,
} from "./types"

export const portalApi = axios.create({
  baseURL: `${APIConfig.portalApi}/portal-api/v1`,
  withCredentials: true,
})

// Intercept 401 responses and redirect to login page
portalApi.interceptors.response.use(
  (response) => response, // pass through successful responses
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/login"
    }
    // return error for further handling if needed
    return Promise.reject(error)
  },
)

export const listPartners = async ({
  offset,
  limit,
}: {
  offset: number
  limit: number
}): Promise<Array<PartnerSummary>> => {
  const { data } = await portalApi.get(
    `/partners?offset=${offset}&limit=${limit}`,
  )
  return data
}

export const listPartnerIds = async (): Promise<Array<number>> => {
  const { data } = await portalApi.get("/partner_ids")
  return data
}

export const createPartner = async (payload: any): Promise<Partner> => {
  const { data } = await portalApi.post("/partners", payload)
  return data
}

export const getPartner = async (
  partnerId: string,
): Promise<PartnerSummary> => {
  const { data } = await portalApi.get(`/partners/${partnerId}`)
  return data
}

export const updatePartner = async ({
  partnerId,
  payload,
}: {
  partnerId: string
  payload: any
}): Promise<Partner> => {
  const { data } = await portalApi.put(`/partners/${partnerId}`, payload)
  return data
}

export const deletePartner = async (partnerId: string): Promise<void> => {
  await portalApi.delete(`/partners/${partnerId}`)
}

export const whoAmI = async (): Promise<User> => {
  const { data } = await portalApi.get("/users/me")
  return data
}

export const createUser = async (payload: any): Promise<any> => {
  const { data } = await portalApi.post("/users", payload)
  return data
}

export const listUsers = async (
  partnerId?: string,
): Promise<UserKratosIdentity[]> => {
  const { data } = await portalApi.get(
    partnerId ? `/users?partnerId=${partnerId}` : "/users",
  )
  return data
}

export const getUser = async (userId: string): Promise<any> => {
  const { data } = await portalApi.get(`/users/${userId}`)
  return data
}

export const updateUser = async ({
  userId,
  payload,
}: {
  userId: string
  payload: UpdateIdentityBody
}): Promise<any> => {
  const { data } = await portalApi.put(`/users/${userId}`, payload)
  return data
}

export const deleteUser = async (userId: string): Promise<any> => {
  const { data } = await portalApi.delete(`/users/${userId}`)
  return data
}

export const getApiKeys = async (): Promise<any> => {
  const { data } = await portalApi.get(`/api-keys`)
  return data
}

export const createAPIKey = async (payload: any): Promise<any> => {
  const { data } = await portalApi.post("/api-keys", payload)
  return data
}

export const deleteApiKey = async (apiKeyId: string): Promise<any> => {
  const { data } = await portalApi.delete(`/api-keys/${apiKeyId}`)
  return data
}

export const generateReport = async (
  orgId: string,
  payload: createUsageReportInput,
): Promise<any> => {
  const { data } = await portalApi.post(`/orgs/${orgId}/reports`, payload)
  return data
}

export const getReport = async (
  orgId: string,
  reportId: string,
  limit: number = 0,
): Promise<AxiosResponse<any, any>> => {
  const limitQuery = limit > 0 ? `?limit=${limit}` : ""
  return portalApi.get(`/orgs/${orgId}/reports/${reportId}${limitQuery}`, {
    headers: {
      "Content-Type": "text/csv",
    },
    responseType: "blob",
  })
}

export const GetColumnsByReportType = async (
  reportType: string,
): Promise<any> => {
  const { data } = await portalApi.get(`/reports/${reportType}/columns`)
  return data
}
