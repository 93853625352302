import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import JsonPrettyPrint from "@/components/JsonPrettyPrint"
import DeviceForm from "@/components/forms/DeviceForm"
import { useDevice } from "@/hooks"

export const Route = createFileRoute("/_auth/devices/$username")({
  component: () => <DeviceDetailPage />,
  staticData: {
    title: "Edit Device",
  },
})

const DeviceDetailPage = () => {
  const { username } = Route.useParams()
  const { data, isPending, error } = useDevice(username)

  if (isPending) {
    return <p>Loading...</p>
  }

  if (error) {
    return <JsonPrettyPrint json={error} />
  }

  return <DeviceForm create={false} device={data} />
}
