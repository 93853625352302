import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import AdminTabs from "@/components/AdminTabs"
import AuthGroupForm from "@/components/forms/AuthGroupForm"

export const Route = createFileRoute(
  "/_auth/administration/auth-groups/create",
)({
  component: () => <CreateAuthGroupPage />,
  staticData: {
    title: "Administration: Create Authorization Group",
  },
})

const CreateAuthGroupPage = () => {
  return (
    <>
      <AdminTabs />
      <div className="bg-white p-4">
        <AuthGroupForm create={true} />
      </div>
    </>
  )
}
