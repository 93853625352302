import { createFileRoute, redirect } from "@tanstack/react-router"

import { UserMetadataPublic } from "@/services/types"

export const Route = createFileRoute("/")({
  beforeLoad: async ({ context }) => {
    const session = await context.auth.checkSession()
    if (session?.active) {
      if (
        (session.identity?.metadata_public as UserMetadataPublic).role ===
        "guest"
      ) {
        throw redirect({ to: "/coverage-map" })
      } else {
        throw redirect({ to: "/devices" })
      }
    } else {
      throw redirect({ to: "/login" })
    }
  },
})
