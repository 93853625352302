import type { MutationStatus } from "@tanstack/query-core"
import { createFileRoute } from "@tanstack/react-router"
import React, { useEffect } from "react"

import SwiftButton from "@/components/Button"
import UsageForm from "@/components/forms/UsageForm"
import LoadingTable from "@/components/tables/LoadingTable"
import UsageTable from "@/components/tables/UsageTable"
import { useUsageReport } from "@/hooks/usageReport"
import type { FormData, UsageReport } from "@/services/types"

export const Route = createFileRoute("/_auth/usage/")({
  component: () => <UsagePage />,
  staticData: {
    title: "Usage",
  },
})

const UsagePage = () => {
  const [reportValues, setReportValues] = React.useState<FormData>()
  const [previewData, setPreviewData] = React.useState<Array<UsageReport>>([])
  const [status, setStatus] = React.useState<MutationStatus>()
  const [executionId, setExecutionId] = React.useState<string>()
  const [url, setUrl] = React.useState<string>()

  const usageReport = useUsageReport({ initialState: "ENQUEUED" })

  const onGenerateReport = (
    values: FormData,
    data: Array<UsageReport>,
    execution_id: string,
  ) => {  
    setReportValues(values)
    setPreviewData(data)
    setExecutionId(execution_id)
  }

  useEffect(() => {
    const fetchFullReport = async (executionId?: string, partnerId?: string) => {
      if (executionId && partnerId) {
        const resp = await usageReport.startWalking({ partnerId, executionId });
        setUrl(URL.createObjectURL(resp.data));
      }
    };
  
    fetchFullReport(executionId, reportValues?.partner_id);
  }, [executionId, reportValues, setUrl]);

  return (
    <>
      <UsageForm
        onGenerateReport={onGenerateReport}
        onChangeStatus={setStatus}
        resetValues={() => {
          usageReport.setState("ENQUEUED")
          setReportValues(undefined)
          setPreviewData([])
          setExecutionId(undefined)
          setUrl("")
        }}
      />
      {status === "pending" && (
        <LoadingTable columns={reportValues?.columns.length ?? 9} rows={20} />
      )}
      {status === "success" && reportValues && (
        <>
          <UsageTable dataInput={reportValues} data={previewData} />
          <div className="flex justify-end items-center py-8 w-52">
            <SwiftButton
              download
              href={url ?? ""}
              color="swift-orange"
              className="ml-auto self-end h-10 text-white bg-swift-orange focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
            >
              DOWNLOAD CSV
            </SwiftButton>
          </div>
        </>
      )}
      {status === "error" && (
        <div className="h-full w-full flex flex-col justify-end items-center p-8">
          There was an error generating the report
        </div>
      )}
    </>
  )
}
