import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import AdminTabs from "@/components/AdminTabs"
import JsonPrettyPrint from "@/components/JsonPrettyPrint"
import AuthGroupsTable from "@/components/tables/AuthGroupsTable"
import LoadingTable from "@/components/tables/LoadingTable"
import { useAuthGroups } from "@/hooks"

type Pagination = {
  offset?: number
  limit?: number
  subscriberId?: string
  name?: string
}

export const Route = createFileRoute("/_auth/administration/auth-groups/")({
  validateSearch: (search: Record<string, unknown>): Pagination => {
    return {
      offset: search.offset as number,
      limit: search.limit as number,
    }
  },
  component: () => <AuthGroupsPage />,
  staticData: {
    title: "Administration: Authorization Groups",
  },
})

const AuthGroupsPage = () => {
  const { offset = 0, limit = 10, subscriberId, name } = Route.useSearch()
  const { data, isPending, error } = useAuthGroups(subscriberId, name)

  if (isPending) {
    return <LoadingTable rows={5} columns={5} />
  }

  if (error) {
    return <JsonPrettyPrint json={error} />
  }

  return (
    <>
      <AdminTabs />
      <AuthGroupsTable
        authGroups={data}
        subscriberId={subscriberId}
        linkTo={Route.fullPath}
        offset={offset}
        limit={limit}
        totalCount={data.length}
        name={name}
      />
    </>
  )
}
