import { Field, Input, Label } from "@headlessui/react"
import { useForm } from "@tanstack/react-form"
import {
  Link,
  createFileRoute,
  redirect,
  useRouterState,
} from "@tanstack/react-router"
import * as React from "react"
import { toast } from "react-hot-toast"
import { z } from "zod"

import Button from "@/components/Button"
import FieldInfo from "@/components/FieldInfo"
import SwiftLogo from "@/components/SwiftLogo"
import Notifications from "@/components/toast/Notifications"
import APIConfig from "@/services/config"
import { orySDK } from "@/services/ory"

const fallback = "/dashboard" as const

export const Route = createFileRoute("/recovery")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
    flow: z.string().optional().catch(""),
  }),
  beforeLoad: async ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: search.redirect || fallback })
    }
  },
  component: () => <AccountRecovery />,
})

const AccountRecovery = () => {
  const isLoading = useRouterState({ select: (s) => s.isLoading })
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [flow, setFlow] = React.useState<any>()

  const search = Route.useSearch()

  const flowId = search.flow

  const isLoggingIn = isLoading || isSubmitting

  React.useEffect(() => {
    if (flow !== undefined) {
      // flow exists already
      return
    }

    // if ?flow is in the url, we fetch it
    if (flowId) {
      orySDK
        .getRecoveryFlow({ id: flowId })
        .then(({ data }) => setFlow(data))
        .catch((err) => {
          // If the flow is no longer valid, request a new flow
          if (err.response.status === 410) {
            window.location.replace(
              `${APIConfig.kratosApi}/self-service/recovery/browser`,
            )
            return
          }

          toast.error("An error occurred. Please try again.")
        })
      return
    }

    // otherwise we initialize it
    orySDK
      .createBrowserRecoveryFlow()
      .then(({ data }) => {
        setFlow(data)
      })
      .catch(() => {
        toast.error("An error occurred. Please try again.")
      })
  }, [flow])

  const form = useForm({
    defaultValues: {
      email: "",
    },
    onSubmit: async ({ value }) => {
      setIsSubmitting(true)

      try {
        if (!flow) {
          console.error("No recovery flow")
          return
        }
        if (value.email === "") {
          return
        }

        orySDK
          .updateRecoveryFlow({
            flow: flow.id,
            updateRecoveryFlowBody: {
              email: value.email,
              method: "link",
              csrf_token: flow.ui.nodes[0].attributes.value,
            },
          })
          .then(async () => {
            toast.success("Recovery email sent")
          })
          .catch(() => {
            toast.error("An error occurred. Please try again.")
          })
      } catch (err) {
        toast.error("An error occurred. Please try again.")
      } finally {
        setIsSubmitting(false)
      }
    },
  })

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="flex items-center justify-center">
          <SwiftLogo />
        </div>
        <h2 className="mt-5 text-center text-2xl leading-9 tracking-tight text-gray-800">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          className="space-y-6"
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            form.handleSubmit()
          }}
        >
          <form.Field
            name="email"
            children={(field) => {
              return (
                <>
                  <Field className="mb-5">
                    <Label
                      htmlFor={field.name}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </Label>
                    <Input
                      tabIndex={1}
                      id={field.name}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                    <FieldInfo field={field} />
                  </Field>
                </>
              )
            }}
          />

          <Button type="submit" color="blue">
            {isLoggingIn ? "Loading..." : "Submit"}
          </Button>

          <div className="text-center mt-5">
            <Link to="/login" className="text-blue-500 hover:text-blue-700">
              Back to login
            </Link>
          </div>
        </form>
      </div>
      <Notifications />
    </div>
  )
}
