// Code generated by protoc-gen-typescript-http. DO NOT EDIT.
/* eslint-disable camelcase */
// @ts-nocheck

/**
 * Expresses what portion of the data is returned from a general query.
 */
export type Pagination = {
  /**
   * Expresses which row from the entire possible query set we will start from.
   */
  offset: number;
  /**
   * Expresses how many entries from the query will be returned. An empty
   * values here will results in a default value being imposed. Know that there
   * is an upper limit, requesting something beyond the limit will results in
   * an immediate error.
   */
  limit?: number;
};

export type CreateAuthorizationGroupPasswordRequest = {
  /**
   * Authorization Group's ID.
   */
  authorization_group_id: string;
  /**
   * Password
   */
  password: string;
  /**
   * Name given to the password.
   */
  name?: string;
  /**
   * Comment or note given to the password.
   */
  comment?: string;
  /**
   * JSON object with string keys/values pairs. It holds user defined data
   * that could assist users in managing and identifying the record.
   */
  metadata?: wellKnownStruct | undefined;
};

// Any JSON value.
type wellKnownStruct = Record<string, unknown>;

export type CreateAuthorizationGroupPasswordResponse = {
  /**
   * Unique identifier (UUID) of the new Authorization Group password.
   */
  id: string;
};

export type UpdateAuthorizationGroupPasswordRequest = {
  /**
   * Authorization Group password unique identifier (UUID) which needs to be updated.
   */
  id: string;
  /**
   * Password
   */
  password?: string;
  /**
   * Name given to the password.
   */
  name?: string;
  /**
   * Comment or note given to the password.
   */
  comment?: string;
  /**
   * JSON object with string keys/values pairs. It holds user defined data
   * that could assist users in managing and identifying the record.
   */
  metadata?: wellKnownStruct | undefined;
};

export type UpdateAuthorizationGroupPasswordResponse = {
};

export type DeleteAuthorizationGroupPasswordRequest = {
  /**
   * Authorization Group password unique identifier (UUID) to be deleted.
   */
  id: string;
};

export type DeleteAuthorizationGroupPasswordResponse = {
};

export type GetAuthorizationGroupPasswordRequest = {
  /**
   * Authorization Group password unique identifier (UUID).
   */
  id: string;
};

export type GetAuthorizationGroupPasswordResponse = {
  /**
   * Object holding the details of the respective Authorization Group password.
   */
  authorization_group_password?: AuthorizationGroupPassword;
};

/**
 * Represents a Skylark Authorization Group password.
 */
export type AuthorizationGroupPassword = {
  /**
   * Password's unique identifier (UUID).
   */
  id: string;
  /**
   * Authorization group ID in which the password is part of.
   */
  authorization_group_id?: string;
  /**
   * Name given to the password.
   */
  name?: string;
  /**
   * Comment or note given to the password.
   */
  comment?: string;
  /**
   * JSON object with string keys/values pairs. It holds user defined data
   * that could assist users in managing and identifying the record.
   */
  metadata?: wellKnownStruct | undefined;
  /**
   * Timestamp when the password was created.
   */
  created_at?: wellKnownTimestamp | undefined;
  /**
   * Timestamp when the password was last updated.
   */
  updated_at?: wellKnownTimestamp | undefined;
};

// Encoded using RFC 3339, where generated output will always be Z-normalized
// and uses 0, 3, 6 or 9 fractional digits.
// Offsets other than "Z" are also accepted.
type wellKnownTimestamp = string;

export type ListAuthorizationGroupPasswordsRequest = {
  /**
   * Expresses the conditions in which we would like to filter authorization
   * group passwords on.
   */
  filter_by?: AuthorizationGroupPasswordFilterBy;
  /**
   * Expresses what portion of the results we would like to focus on.
   */
  pagination?: Pagination;
};

/**
 * Represents the possible ways in which we can filter the list of
 * authorization group passwords. Each entry imposes a filtering criteria on
 * the authorization group password query, multiple entries imposes an `and`
 * filter.
 */
export type AuthorizationGroupPasswordFilterBy = {
  /**
   * List of all the authorization group password IDs (UUID) which we wish to
   * include in the query. Empty list doesn't impose a filter.
   */
  ids: string[];
  /**
   * Lists all the authorization group passwords which are owner by the list of
   * subscriber IDs (UUID). Empty list doesn't impose a filter.
   */
  subscriber_ids: string[];
};

export type ListAuthorizationGroupPasswordsResponse = {
  /**
   * List of authorization group passwords satisfying the query
   */
  authorization_group_passwords: AuthorizationGroupPassword[];
};

export type ListAuthorizationGroupPasswordIdsRequest = {
  /**
   * Expresses the conditions in which we would like to filter authorization
   * group passwords on.
   */
  filter_by?: AuthorizationGroupPasswordFilterBy;
};

export type ListAuthorizationGroupPasswordIdsResponse = {
  /**
   * List of authorization group passwords satisfying the query, only the "id"
   * field will be populated.
   */
  authorization_group_passwords: AuthorizationGroupPassword[];
};

export type CreateAuthorizationGroupRequest = {
  /**
   * Subscriber ID (UUID) which the authorization group will be associated with.
   */
  subscriber_id: string;
  /**
   * Authorization group's id (aka authorization group prefix) is a unique
   * identifier for the group. Valid entry here consists of a 3 to 12 character
   * long string which comprises of only lowercase alphanumeric characters. Not
   * only is this a unique identifier, this will also be part of a device's
   * NTRIP username, it will follow the format of
   * "<authorization group id>@<device name>".
   * Since this field will be unique to Skylark, this field will be used as the
   * unique identifier to any authorization groups, hence the name.
   */
  id: string;
  /**
   * Name given to the group.
   */
  name?: string;
  /**
   * Comment or note given to the group.
   */
  comment?: string;
};

export type CreateAuthorizationGroupResponse = {
};

export type UpdateAuthorizationGroupRequest = {
  /**
   * Authorization group's id.
   */
  id: string;
  /**
   * Name given to the group.
   */
  name?: string;
  /**
   * Comment or note given to the group.
   */
  comment?: string;
};

export type UpdateAuthorizationGroupResponse = {
};

export type DeleteAuthorizationGroupRequest = {
  /**
   * Authorization group's id.
   */
  id: string;
};

export type DeleteAuthorizationGroupResponse = {
};

export type GetAuthorizationGroupRequest = {
  /**
   * Authorization group's id.
   */
  id: string;
};

export type GetAuthorizationGroupResponse = {
  /**
   * Object holding the details of the respective authorization group.
   */
  authorization_group?: AuthorizationGroup;
};

/**
 * Represents a Skylark authorization group.
 */
export type AuthorizationGroup = {
  /**
   * Subscriber ID (UUID) which the authorization group is associated with.
   */
  subscriber_id?: string;
  /**
   * Authorization group's id.
   */
  id: string;
  /**
   * Name given to the group.
   */
  name?: string;
  /**
   * Comment or note given to the group.
   */
  comment?: string;
  /**
   * Timestamp when the group was created.
   */
  created_at?: wellKnownTimestamp | undefined;
  /**
   * Timestamp when the group was last updated.
   */
  updated_at?: wellKnownTimestamp | undefined;
};

export type ListAuthorizationGroupsRequest = {
  /**
   * Expresses the conditions in which we would like to filter authorization
   * group on.
   */
  filter_by?: AuthorizationGroupFilterBy;
  /**
   * Expresses what portion of the results we would like to focus on.
   */
  pagination?: Pagination;
};

/**
 * Represents the possible ways in which we can filter the list of
 * authorization groups. Each entry imposes a filtering criteria on the
 * authorization group query, multiple entries imposes an `and` filter.
 */
export type AuthorizationGroupFilterBy = {
  /**
   * List of all the authorization group IDs which we wish to include in the
   * query. Empty list doesn't impose a filter.
   */
  ids: string[];
  /**
   * Lists all the authorization group which are owner by the list of
   * subscriber IDs (UUID). Empty list doesn't impose a filter.
   */
  subscriber_ids: string[];
};

export type ListAuthorizationGroupsResponse = {
  /**
   * List of authorization group satisfying the query
   */
  authorization_groups: AuthorizationGroup[];
};

export type ListAuthorizationGroupIdsRequest = {
  /**
   * Expresses the conditions in which we would like to filter authorization
   * group on.
   */
  filter_by?: AuthorizationGroupFilterBy;
};

export type ListAuthorizationGroupIdsResponse = {
  /**
   * List of authorization group satisfying the query, only the "id" field will
   * be populated.
   */
  authorization_groups: AuthorizationGroup[];
};

/**
 * Skylark service type
 */
export type SkylarkType =
  | "SKYLARK_UNSPECIFIED"
  | "SKYLARK_CX"
  | "SKYLARK_DX"
  | "SKYLARK_NX_RTK";
export type CreateRegisteredDeviceRequest = {
  /**
   * Subscriber ID (UUID) which will own the device.
   */
  subscriber_id: string;
  /**
   * Indicates if the device is allowed to be used in our Skylark system.
   */
  enabled: boolean;
  /**
   * Username that a device will use to login to Skylark. If the device is part
   * of an authorization group, the device's username must start with the
   * authorization group's identifier (also known an a "prefix") followed by
   * the "@" symbol followed by a device identifier of your choice. Below is
   * the list of characters allowed for the device identifier:
   * 1. alphanumeric characters
   * 2. dashes "-"
   * 3  dots "."
   * 4. underscores "_"
   * 5. plus symbol "+"
   * If the username is not part of an authorization group, the username
   * should just consist of the device identifier of your choice.
   * NOTE: the username will have to be unique across our Skylark network, so
   * be prepared to have the request rejected if there is a clash.
   */
  username: string;
  /**
   * Password associated with the device. This field only needs to be filled
   * out when the username is part of an authorization group, otherwise it can
   * be left empty.
   */
  password?: string;
  /**
   * Skylark type associated with the device.
   */
  skylark_type: SkylarkType;
  /**
   * Name given to the device.
   */
  name?: string;
  /**
   * Comment or note given to the device.
   */
  comment?: string;
  /**
   * Indicates when the device's license expires. If the expired at time is the
   * UNIX epoch ("1970-01-01T00:00:00Z"), than the device never expires. A time
   * before the UNIX epoch is invalid and anything greater than implies a valid
   * time point in which the device will expire. Not specifying a value implies
   * that the device never expires.
   */
  expires_at: wellKnownTimestamp | undefined;
  /**
   * Email to receive notifications.
   */
  email?: string;
  /**
   * If 'allow_notifications' is enabled, the system will send different notifications to the email
   * These notifications include:
   * - Device creation
   * - Device deletion
   * - Device expiration alerts
   * - Device password rotation alerts
   * Empty values implies no notifications (false)
   */
  allow_notifications?: boolean;
};

export type CreateRegisteredDeviceResponse = {
};

export type CreatePreProvisionedDeviceRequest = {
  /**
   * Subscriber identifier (UUID) which will own the device.
   */
  subscriber_id: string;
  /**
   * Indicates if the device is allowed to be used in our Skylark system.
   */
  enabled: boolean;
  /**
   * Username that a device will use to login to Skylark. If the device is part
   * of an authorization group, the device's username must start with the
   * authorization group's identifier (also known an a "prefix") followed by
   * the "@" symbol followed by a device identifier of your choice. Below is
   * the list of characters allowed for the device identifier:
   * 1. alphanumeric characters
   * 2. dashes "-"
   * 3  dots "."
   * 4. underscores "_"
   * 5. plus symbol "+"
   * If the username is not part of an authorization group, the username
   * should just consist of the device identifier of your choice.
   * NOTE: the username will have to be unique across our Skylark network, so
   * be prepared to have the request rejected if there is a clash.
   */
  username: string;
  /**
   * Password associated with the device. This field only needs to be filled
   * out when the username is part of an authorization group, otherwise it can
   * be left empty.
   */
  password?: string;
  /**
   * Skylark type associated with the device.
   */
  skylark_type: SkylarkType;
  /**
   * Name given to the device.
   */
  name?: string;
  /**
   * Comment or note given to the device.
   */
  comment?: string;
  /**
   * Indicates how long the device will be licenced to access the system from
   * the moment it first logs in. A value of zero implied an unlimited license,
   * anything greater than that be rounded up to the nearest day. Not
   * specifying a value implies an indefinite license.
   * Users need to enter the duration in units of seconds. So one week would
   * be represented as "604800s".
   */
  expires_after: wellKnownDuration | undefined;
  /**
   * Email to receive notifications.
   */
  email?: string;
  /**
   * If 'allow_notifications' is enabled, the system will send different notifications to the email
   * These notifications include:
   * - Device creation
   * - Device deletion
   * - Device expiration alerts
   * - Device password rotation alerts
   * Empty values implies no notifications (false)
   */
  allow_notifications?: boolean;
};

// Generated output always contains 0, 3, 6, or 9 fractional digits,
// depending on required precision, followed by the suffix "s".
// Accepted are any fractional digits (also none) as long as they fit
// into nano-seconds precision and the suffix "s" is required.
type wellKnownDuration = string;

export type CreatePreProvisionedDeviceResponse = {
};

export type UpdateRegisteredDeviceRequest = {
  /**
   * Indicates if the device is allowed to be used in our Skylark system.
   */
  enabled?: boolean;
  /**
   * Username that a device will use to login to Skylark. If the device is part
   * of an authorization group, the device's username must start with the
   * authorization group's identifier (also known an a "prefix") followed by
   * the "@" symbol followed by a device identifier of your choice. Below is
   * the list of characters allowed for the device identifier:
   * 1. alphanumeric characters
   * 2. dashes "-"
   * 3  dots "."
   * 4. underscores "_"
   * 5. plus symbol "+"
   * If the username is not part of an authorization group, the username
   * should just consist of the device identifier of your choice.
   * NOTE: the username will have to be unique across our Skylark network, so
   * be prepared to have the request rejected if there is a clash.
   */
  username: string;
  /**
   * Password associated with the device. This field only needs to be filled
   * out when you'd like to update the password for a device that is not part
   * of an authorization group, otherwise leave it empty.
   */
  password?: string;
  /**
   * Skylark type associated with the device.
   */
  skylark_type?: SkylarkType;
  /**
   * Name given to the device.
   */
  name?: string;
  /**
   * Comment or note given to the device.
   */
  comment?: string;
  /**
   * Indicates when the device's license expires. If the expired at time is the
   * UNIX epoch ("1970-01-01T00:00:00Z"), than the device never expires. A time
   * before the UNIX epoch is invalid and anything greater than implies a valid
   * time point in which the device will expire.
   */
  expires_at?: wellKnownTimestamp | undefined;
  /**
   * Email to receive notifications. If set to empty string, the email entry will be removed.
   */
  email?: string;
  /**
   * If 'allow_notifications' is enabled, the system will send different notifications to the email
   * These notifications include:
   * - Device creation
   * - Device deletion
   * - Device expiration alerts
   * - Device password rotation alerts
   */
  allow_notifications?: boolean;
};

export type UpdateRegisteredDeviceResponse = {
};

export type UpdatePreProvisionedDeviceRequest = {
  /**
   * Indicates if the device is allowed to be used in our Skylark system.
   */
  enabled?: boolean;
  /**
   * Username that a device will use to login to Skylark. If the device is part
   * of an authorization group, the device's username must start with the
   * authorization group's identifier (also known an a "prefix") followed by
   * the "@" symbol followed by a device identifier of your choice. Below is
   * the list of characters allowed for the device identifier:
   * 1. alphanumeric characters
   * 2. dashes "-"
   * 3  dots "."
   * 4. underscores "_"
   * 5. plus symbol "+"
   * If the username is not part of an authorization group, the username
   * should just consist of the device identifier of your choice.
   * NOTE: the username will have to be unique across our Skylark network, so
   * be prepared to have the request rejected if there is a clash.
   */
  username: string;
  /**
   * Password associated with the device. This field only needs to be filled
   * out when you'd like to update the password for a device that is not part
   * of an authorization group, otherwise leave it empty.
   */
  password?: string;
  /**
   * Skylark type associated with the device.
   */
  skylark_type?: SkylarkType;
  /**
   * Name given to the device.
   */
  name?: string;
  /**
   * Comment or note given to the device.
   */
  comment?: string;
  /**
   * With this we can set the activated status of the device.
   */
  activated?: boolean;
  /**
   * Indicates how long the device will be licenced to access the system from
   * the moment it first logs in. A value of zero implied an unlimited license,
   * anything greater than that be rounded up to the nearest day.
   * Users need to enter the duration in units of seconds. So one week would
   * be represented as "604800s".
   */
  expires_after?: wellKnownDuration | undefined;
  /**
   * Indicates when the device's license expires. This value is only valid if
   * the device is activate. At the moment that a device is activated, if the
   * "expires after" field is a none zero value, this field gets set to the
   * time of activation plus the "expires after" duration, otherwise it sets
   * it to the UNIX epoch ("1970-01-01T00:00:00Z") which flags that the device
   * never expires.
   */
  expires_at?: wellKnownTimestamp | undefined;
  /**
   * Email to receive notifications. If set to empty string, the email entry will be removed.
   */
  email?: string;
  /**
   * If 'allow_notifications' is enabled, the system will send different notifications to the email
   * These notifications include:
   * - Device creation
   * - Device deletion
   * - Device expiration alerts
   * - Device password rotation alerts
   */
  allow_notifications?: boolean;
};

export type UpdatePreProvisionedDeviceResponse = {
};

export type UpdateAutoRegisteredDeviceRequest = {
  /**
   * Indicates if the device is allowed to be used in our Skylark system.
   */
  enabled?: boolean;
  /**
   * Username that a device will use to login to Skylark. If the device is part
   * of an authorization group, the device's username must start with the
   * authorization group's identifier (also known an a "prefix") followed by
   * the "@" symbol followed by a device identifier of your choice. Below is
   * the list of characters allowed for the device identifier:
   * 1. alphanumeric characters
   * 2. dashes "-"
   * 3  dots "."
   * 4. underscores "_"
   * 5. plus symbol "+"
   * If the username is not part of an authorization group, the username
   * should just consist of the device identifier of your choice.
   * NOTE: the username will have to be unique across our Skylark network, so
   * be prepared to have the request rejected if there is a clash.
   */
  username: string;
  /**
   * Skylark type associated with the device.
   */
  skylark_type?: SkylarkType;
  /**
   * Name given to the device.
   */
  name?: string;
  /**
   * Comment or note given to the device.
   */
  comment?: string;
  /**
   * Indicates when the device's license expires. If the expired at time is the
   * UNIX epoch ("1970-01-01T00:00:00Z"), than the device never expires. A time
   * before the UNIX epoch is invalid and anything greater than implies a valid
   * time point in which the device will expire.
   */
  expires_at?: wellKnownTimestamp | undefined;
};

export type UpdateAutoRegisteredDeviceResponse = {
};

export type DeleteDeviceRequest = {
  /**
   * Username of the device we wish to delete.
   */
  username: string;
};

export type DeleteDeviceResponse = {
};

export type GetDeviceRequest = {
  /**
   * Username of the device we wish to obtain data from.
   */
  username: string;
};

export type GetDeviceResponse = {
  /**
   * If the username exists in Skylark, the devices details are returned,
   * otherwise an empty value is returned.
   */
  device?: Device;
};

/**
 * Represents a Skylark device. Term is synonymous with the word "credentials".
 */
export type Device = {
  /**
   * Subscriber ID (UUID) which owns the device.
   */
  subscriber_id?: string;
  /**
   * Flag to indicate if the device can be used for any purpose in the system.
   * Value of `false` indicates that device can not login through our system
   * and is deem disabled.
   */
  enabled?: boolean;
  /**
   * Username that a device will use to login to Skylark. If the device is part
   * of an authorization group, the device's username must start with the
   * authorization group's identifier (also known an a "prefix") followed by
   * the "@" symbol followed by a device identifier of your choice. Below is
   * the list of characters allowed for the device identifier:
   * 1. alphanumeric characters
   * 2. dashes "-"
   * 3  dots "."
   * 4. underscores "_"
   * 5. plus symbol "+"
   * If the username is not part of an authorization group, the username
   * should just consist of the device identifier of your choice.
   * NOTE: the username will have to be unique across our Skylark network, so
   * be prepared to have the request rejected if there is a clash.
   */
  username: string;
  registered?: Device_Registered;
  pre_provisioned?: Device_PreProvisioned;
  auto_registered?: Device_AutoRegistered;
  /**
   * Skylark type associated with the device.
   */
  skylark_type?: SkylarkType;
  /**
   * Name given to the device.
   */
  name?: string;
  /**
   * Comment or note given to the device.
   */
  comment?: string;
  /**
   * Specifies the time point in which the device can no longer access the
   * system. If the value is the UNIX epoch ("1970-01-01T00:00:00Z"), than the
   * device never expires, any value above that are time points in which the
   * device is valid until.
   * NOTE: this field is conditionally enforced only when a device is "active".
   * Devices with registration type "Registered" and "Auto-Registered" are
   * implicitly active, "Pre-Provisioned" needs to have there `activated` field
   * set to true for this field to be enforced.
   */
  expires_at?: wellKnownTimestamp | undefined;
  /**
   * Specifies when the device first connected to our system. For "Auto-Registered"
   * devices, this field will be the same as the `created_at` field. If this field
   * is the UNIX epoch ("1970-01-01T00:00:00Z"), than the device has never connected.
   * A time before the UNIX epoch is invalid and anything greater than it implies a valid
   * time point in which the device connected.
   */
  first_connection_time?: wellKnownTimestamp | undefined;
  /**
   * Specifies when the device was created in Skylark
   */
  created_at?: wellKnownTimestamp | undefined;
  /**
   * Specifies when the device record was last updated
   */
  updated_at?: wellKnownTimestamp | undefined;
  /**
   * Email address associated with the device. Empty string indicates that email was not set.
   */
  email?: string;
  /**
   * Indicates if the user set the device to receive notifications.
   */
  allow_notifications?: boolean;
};

/**
 * Device that has been explicitly registered with the system and activated
 * at creation.
 */
export type Device_Registered = {
};

/**
 * Device that has been created but has not been activated. Device is
 * activated the moment a user logs in for the first time, at which point
 * its license expiry date it determined by how long its been given the
 * licence for (via "expires_after" field).
 */
export type Device_PreProvisioned = {
  /**
   * Indicates if the device has logged in an initiated its activation
   * period.
   */
  activated: boolean;
  /**
   * Specifies how long the device will be given a license for from the
   * moment it activates itself on first login. This field will set the
   * device's `expires_at` field. A value of zero implied an unlimited
   * license, otherwise it will be the number of days.
   */
  expires_after?: wellKnownDuration | undefined;
};

/**
 * Device that has been automatically registered with the system upon first
 * login. These device can not be created ahead of time and can only be
 * created through a zero trust endpoint upon login.
 */
export type Device_AutoRegistered = {
};

export type ListDevicesRequest = {
  /**
   * Expresses the conditions in which we would like to filter devices on.
   */
  filter_by?: DeviceFilterBy;
  /**
   * Expresses what portion of the results we would like to focus on.
   */
  pagination?: Pagination;
};

/**
 * Represents the possible ways in which we can filter the list of devices.
 * Each entry imposes a filtering criteria on the device query, multiple
 * entries imposes an `and` filter.
 */
export type DeviceFilterBy = {
  /**
   * List of all the device usernames which we wish to include in the query.
   * Empty list doesn't impose a filter.
   */
  usernames: string[];
  /**
   * Lists all the device which are owner by the list of subscriber IDs (UUID).
   * Empty list doesn't impose a filter.
   */
  subscriber_ids: string[];
};

export type ListDevicesResponse = {
  /**
   * List of devices satisfying the query
   */
  devices: Device[];
};

export type ListDeviceUsernamesRequest = {
  /**
   * Expresses the conditions in which we would like to filter devices on.
   */
  filter_by?: DeviceFilterBy;
};

export type ListDeviceUsernamesResponse = {
  /**
   * List of devices satisfying the query, only the "username" field will be
   * populated.
   */
  devices: Device[];
};

export type CreateSubscriberRequest = {
  /**
   * Name given to the subscriber.
   */
  name: string;
  /**
   * Comment or note given to the subscriber.
   */
  comment?: string;
  /**
   * Partner ID (UUID) which owns the subscriber.
   */
  partner_id?: string;
};

export type CreateSubscriberResponse = {
  /**
   * Unique identifier (UUID) of the new subscriber.
   */
  id: string;
};

export type UpdateSubscriberRequest = {
  /**
   * Subscriber ID, must be a valid UUID
   */
  id: string;
  /**
   * Subscriber name, must be at least 1 character long
   */
  name?: string;
  /**
   * Comment or note given to the subscriber.
   */
  comment?: string;
};

export type UpdateSubscriberResponse = {
};

export type DeleteSubscriberRequest = {
  /**
   * Subscriber ID, must be a valid UUID
   */
  id: string;
};

export type DeleteSubscriberResponse = {
};

export type GetSubscriberRequest = {
  /**
   * Subscriber ID, must be a valid UUID
   */
  id: string;
};

export type GetSubscriberResponse = {
  subscriber: Subscriber;
};

export type Subscriber = {
  /**
   * Subscriber ID (UUID)
   */
  id: string;
  /**
   * Partner ID (UUID) which owns the subscriber.
   */
  partner_id?: string;
  /**
   * Subscriber name
   */
  name?: string;
  /**
   * Comment or note given to the subscriber.
   */
  comment?: string;
  /**
   * Timestamp of the creation
   */
  created_at?: wellKnownTimestamp | undefined;
  /**
   * Timestamp of the last update
   */
  updated_at?: wellKnownTimestamp | undefined;
};

export type ListSubscribersRequest = {
  /**
   * Expresses the conditions in which we would like to filter subscribers on.
   */
  filter_by?: SubscriberFilterBy;
  /**
   * Expresses what portion of the results we would like to focus on.
   */
  pagination?: Pagination;
};

/**
 * Represents the possible ways in which we can filter the list of subscribers.
 * Each entry imposes a filtering criteria on the subscriber query, multiple
 * entries imposes an `and` filter.
 */
export type SubscriberFilterBy = {
  /**
   * List of all the subscriber ids which we wish to include in the query.
   * Empty list doesn't impose a filter.
   */
  ids: string[];
};

export type ListSubscribersResponse = {
  /**
   * List of subscribers satisfying the query
   */
  subscribers: Subscriber[];
};

export type ListSubscriberIdsRequest = {
  /**
   * Expresses the conditions in which we would like to filter subscribers on.
   */
  filter_by?: SubscriberFilterBy;
};

export type ListSubscriberIdsResponse = {
  /**
   * List of subscribers satisfying the query, only the "id" field will be
   */
  subscribers: Subscriber[];
};

export type CreateX509CertificateRequest = {
  /**
   * Subscriber identifier (UUID) which will own the certificate.
   */
  subscriber_id: string;
  /**
   * Flags if the certificate can be used to authenticate.
   */
  enabled: boolean;
  /**
   * Raw X509 certificate.
   */
  certificate: string;
  /**
   * Name given to the certificate.
   */
  name?: string;
  /**
   * Comment or note given to the certificate.
   */
  comment?: string;
};

export type CreateX509CertificateResponse = {
  /**
   * Unique identifier (UUID) of the new X509 certificate.
   */
  id: string;
};

export type UpdateX509CertificateRequest = {
  /**
   * Unique identifier (UUID) of the X509 certificate to be updated.
   */
  id: string;
  /**
   * Flags if the certificate can be used to authenticate.
   */
  enabled?: boolean;
  /**
   * Name given to the certificate.
   */
  name?: string;
  /**
   * Comment or note given to the certificate.
   */
  comment?: string;
};

export type UpdateX509CertificateResponse = {
};

export type DeleteX509CertificateRequest = {
  /**
   * Unique identifier (UUID) of the X509 certificate to delete.
   */
  id: string;
};

export type DeleteX509CertificateResponse = {
};

export type GetX509CertificateRequest = {
  /**
   * Unique identifier (UUID) of the X509 certificate to obtain.
   */
  id: string;
};

export type GetX509CertificateResponse = {
  /**
   * Certificate details
   */
  x509_certificate?: X509Certificate;
};

/**
 * Represents a Skylark Authorization Group X509 certificate.
 */
export type X509Certificate = {
  /**
   * Certificate's identifier (UUID).
   */
  id: string;
  /**
   * Subscriber identifier (UUID) that owns this certificate.
   */
  subscriber_id?: string;
  /**
   * Indicates whether the certificate can be used.
   */
  enabled?: boolean;
  /**
   * Certificate's SHA 256 fingerprint, expressed in hexadecimal format.
   */
  sha256_fingerprint?: string;
  /**
   * Name given to the certificate.
   */
  name?: string;
  /**
   * Comment or note given to the certificate.
   */
  comment?: string;
  /**
   * Timestamp when the certificate starts being valid.
   */
  not_before?: wellKnownTimestamp | undefined;
  /**
   * Timestamp when the certificate stops being valid.
   */
  not_after?: wellKnownTimestamp | undefined;
  /**
   * Timestamp when the certificate was created.
   */
  created_at?: wellKnownTimestamp | undefined;
  /**
   * Timestamp when the certificate was last updated.
   */
  updated_at?: wellKnownTimestamp | undefined;
};

export type ListX509CertificatesRequest = {
  /**
   * Expresses the conditions in which we would like to filter X.509
   * certificates on.
   */
  filter_by?: X509CertificateFilterBy;
  /**
   * Expresses what portion of the results we would like to focus on.
   */
  pagination?: Pagination;
};

/**
 * Represents the possible ways in which we can filter the list of X.509
 * certificates. Each entry imposes a filtering criteria on the certificate
 * query, multiple entries imposes an `and` filter.
 */
export type X509CertificateFilterBy = {
  /**
   * List of all the X509 certificate IDs (UUID) which we wish to include in
   * the query. Empty list doesn't impose a filter.
   */
  ids: string[];
  /**
   * List of all the X.509 certificates which are owner by the list of
   * subscriber IDs (UUID). Empty list doesn't impose a filter.
   */
  subscriber_ids: string[];
};

export type ListX509CertificatesResponse = {
  /**
   * List of X.509 certificates satisfying the query
   */
  x509_certificates: X509Certificate[];
};

export type ListX509CertificateIdsRequest = {
  /**
   * Expresses the conditions in which we would like to filter X.509
   * certificates on.
   */
  filter_by?: X509CertificateFilterBy;
};

export type ListX509CertificateIdsResponse = {
  /**
   * List of X.509 certificates satisfying the query, only the "id"
   * field will be populated.
   */
  x509_certificates: X509Certificate[];
};

export type IssueX509CertificateRequest = {
  /**
   * Certificate signing request (CSR) document
   */
  certificate_signing_request: string;
  /**
   * Specifies on what date/time the certificate becomes valid.
   */
  not_before: wellKnownTimestamp | undefined;
  /**
   * Specifies on what date/time the certificate losses its validity. Time
   * between 'not before' and 'not after' should not exceed 13 months (397
   * days).
   */
  not_after: wellKnownTimestamp | undefined;
};

export type IssueX509CertificateResponse = {
  /**
   * X.509 certificate generated from CSR
   */
  x509_certificate: string;
  /**
   * X.509 certificate chain
   */
  x509_certificate_chain: string;
};

export interface AuthorizationGroups<T = unknown> {
  /**
   * Allows users to create an authorization group.
   * An authorization group is a collection of device's whose username starts
   * with an "Authorization Group ID" followed by an "@" symbol followed by a
   * number of characters that uniquely identify that device. This grouping of
   * devices can login to Skylark using any registered "Authorization Group
   * Password" sitting under the authorization group. To help illustrate, if
   * we had an authorization group with the ID of "swift" and a registered
   * device with the username "swift@my-device" with 2 authorization group
   * passwords sitting under the "swift" group, one with value "my-secret" and
   * another with "123password", than the device can login using either
   * password.
   */
  CreateAuthorizationGroup(request: CreateAuthorizationGroupRequest, options?: T): Promise<CreateAuthorizationGroupResponse>;
  /**
   * Allows users to update an authorization group.
   * Read the "Create Authorization Group" API for details as to what an
   * "authorization group" is.
   */
  UpdateAuthorizationGroup(request: UpdateAuthorizationGroupRequest, options?: T): Promise<UpdateAuthorizationGroupResponse>;
  /**
   * Allows users to delete an authorization group.
   */
  DeleteAuthorizationGroup(request: DeleteAuthorizationGroupRequest, options?: T): Promise<DeleteAuthorizationGroupResponse>;
  /**
   * Allows users to obtain details about an authorization group.
   */
  GetAuthorizationGroup(request: GetAuthorizationGroupRequest, options?: T): Promise<GetAuthorizationGroupResponse>;
  /**
   * Allows users to obtain details about a set of authorization groups.
   * NOTE: the API does put a cap on the number of results returned. To obtain
   * an uncapped list, please use the "List Authorization Group IDs" API.
   */
  ListAuthorizationGroups(request: ListAuthorizationGroupsRequest, options?: T): Promise<ListAuthorizationGroupsResponse>;
  /**
   * Allows users to obtain the IDs of a set of authorization groups.
   * NOTE: API returns only the authorization group IDs that satisfies the
   * filtering criteria. Users can use the "List Authorization Groups" API to
   * obtain the details for each authorization group. Unlike the "List
   * Authorization Groups" API, this API does not cap on the number of results,
   * this API should primarily be used for initiating a bulk API calls.
   */
  ListAuthorizationGroupIds(request: ListAuthorizationGroupIdsRequest, options?: T): Promise<ListAuthorizationGroupIdsResponse>;
}

/** The URIs for AuthorizationGroups */
export interface AuthorizationGroupsURIs<T = unknown> {
  /** Get the URI of `CreateAuthorizationGroup` method */
  GetCreateAuthorizationGroupURI(request: CreateAuthorizationGroupRequest, options?: T): string;
  /** Get the URI of `UpdateAuthorizationGroup` method */
  GetUpdateAuthorizationGroupURI(request: UpdateAuthorizationGroupRequest, options?: T): string;
  /** Get the URI of `DeleteAuthorizationGroup` method */
  GetDeleteAuthorizationGroupURI(request: DeleteAuthorizationGroupRequest, options?: T): string;
  /** Get the URI of `GetAuthorizationGroup` method */
  GetGetAuthorizationGroupURI(request: GetAuthorizationGroupRequest, options?: T): string;
  /** Get the URI of `ListAuthorizationGroups` method */
  GetListAuthorizationGroupsURI(request: ListAuthorizationGroupsRequest, options?: T): string;
  /** Get the URI of `ListAuthorizationGroupIds` method */
  GetListAuthorizationGroupIdsURI(request: ListAuthorizationGroupIdsRequest, options?: T): string;
}

export function getAuthorizationGroupsURIs<T = unknown>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlerOptions: {
    mapStringify?: (map: Record<string, unknown>) => string;
  } = {},
): AuthorizationGroupsURIs<T> {
  return {
    GetCreateAuthorizationGroupURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `authorization_groups`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetUpdateAuthorizationGroupURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `authorization_groups/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetDeleteAuthorizationGroupURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `authorization_groups/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetGetAuthorizationGroupURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `authorization_groups/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetListAuthorizationGroupsURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `authorization_groups`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      if (request.filter_by?.ids) {
        request.filter_by.ids.forEach((x) => {
          queryParams.push(`filter_by.ids=${encodeURIComponent(x.toString())}`);
        })
      }
      if (request.filter_by?.subscriber_ids) {
        request.filter_by.subscriber_ids.forEach((x) => {
          queryParams.push(`filter_by.subscriber_ids=${encodeURIComponent(x.toString())}`);
        })
      }
      if (request.pagination?.offset) {
        queryParams.push(`pagination.offset=${encodeURIComponent(request.pagination.offset.toString())}`);
      }
      if (request.pagination?.limit) {
        queryParams.push(`pagination.limit=${encodeURIComponent(request.pagination.limit.toString())}`);
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetListAuthorizationGroupIdsURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `authorization_group_ids`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      if (request.filter_by?.ids) {
        request.filter_by.ids.forEach((x) => {
          queryParams.push(`filter_by.ids=${encodeURIComponent(x.toString())}`);
        })
      }
      if (request.filter_by?.subscriber_ids) {
        request.filter_by.subscriber_ids.forEach((x) => {
          queryParams.push(`filter_by.subscriber_ids=${encodeURIComponent(x.toString())}`);
        })
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
  };
}
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type RequestType<T = Record<string, any> | string | null> = {
  path: string;
  method: string;
  body: T;
};

type RequestHandler<T = unknown> = (
  request: RequestType & T,
  meta: { service: string, method: string },
) => Promise<unknown>;

export function createAuthorizationGroupsClient<T = unknown>(
  handler: RequestHandler<T>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlerOptions: {
    mapStringify?: (map: Record<string, unknown>) => string;
  } = {},
): AuthorizationGroups<T> {
  const uris = getAuthorizationGroupsURIs<T>(handlerOptions);
  return {
    CreateAuthorizationGroup(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetCreateAuthorizationGroupURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "POST",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroups",
        method: "CreateAuthorizationGroup",
      }) as Promise<CreateAuthorizationGroupResponse>;
    },
    UpdateAuthorizationGroup(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetUpdateAuthorizationGroupURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "PUT",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroups",
        method: "UpdateAuthorizationGroup",
      }) as Promise<UpdateAuthorizationGroupResponse>;
    },
    DeleteAuthorizationGroup(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetDeleteAuthorizationGroupURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "DELETE",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroups",
        method: "DeleteAuthorizationGroup",
      }) as Promise<DeleteAuthorizationGroupResponse>;
    },
    GetAuthorizationGroup(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetGetAuthorizationGroupURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroups",
        method: "GetAuthorizationGroup",
      }) as Promise<GetAuthorizationGroupResponse>;
    },
    ListAuthorizationGroups(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetListAuthorizationGroupsURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroups",
        method: "ListAuthorizationGroups",
      }) as Promise<ListAuthorizationGroupsResponse>;
    },
    ListAuthorizationGroupIds(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetListAuthorizationGroupIdsURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroups",
        method: "ListAuthorizationGroupIds",
      }) as Promise<ListAuthorizationGroupIdsResponse>;
    },
  };
}
export interface AuthorizationGroupPasswords<T = unknown> {
  /**
   * Allows users to create an authorization group password.
   * Authorization group passwords are objects that live under authorization
   * groups. Each authorization group can have any number of passwords, any of
   * which can be used to login a device that is part of an authorization
   * group. Having a list of passwords allows users to transition devices from
   * one password to another in a graceful manner.
   */
  CreateAuthorizationGroupPassword(request: CreateAuthorizationGroupPasswordRequest, options?: T): Promise<CreateAuthorizationGroupPasswordResponse>;
  /**
   * Allows users to update an authorization group password.
   * Read the "Create Authorization Group Password" API for details as to what
   * an authorization group password is.
   */
  UpdateAuthorizationGroupPassword(request: UpdateAuthorizationGroupPasswordRequest, options?: T): Promise<UpdateAuthorizationGroupPasswordResponse>;
  /**
   * Allows users to delete an authorization group password.
   */
  DeleteAuthorizationGroupPassword(request: DeleteAuthorizationGroupPasswordRequest, options?: T): Promise<DeleteAuthorizationGroupPasswordResponse>;
  /**
   * Allows users to obtain details about a particular authorization group
   * password.
   */
  GetAuthorizationGroupPassword(request: GetAuthorizationGroupPasswordRequest, options?: T): Promise<GetAuthorizationGroupPasswordResponse>;
  /**
   * Allows users to obtain details about a set of authorization group passwords.
   * NOTE: the API does put a cap on the number of results returned. To obtain
   * an uncapped list, please use the "List Authorization Group Password IDs"
   * API.
   */
  ListAuthorizationGroupPasswords(request: ListAuthorizationGroupPasswordsRequest, options?: T): Promise<ListAuthorizationGroupPasswordsResponse>;
  /**
   * Allows users to obtain the IDs of a set of authorization group passwords.
   * NOTE: API returns only the authorization group password IDs that satisfies
   * the filtering criteria. Users can use the "List Authorization Group
   * Password" API to obtain the details for each item. Unlike the "List
   * Authorization Group Password" API, this API does not cap on the number
   * of results, this API should primarily be used for initiating a bulk API
   * calls.
   */
  ListAuthorizationGroupPasswordIds(request: ListAuthorizationGroupPasswordIdsRequest, options?: T): Promise<ListAuthorizationGroupPasswordIdsResponse>;
}

/** The URIs for AuthorizationGroupPasswords */
export interface AuthorizationGroupPasswordsURIs<T = unknown> {
  /** Get the URI of `CreateAuthorizationGroupPassword` method */
  GetCreateAuthorizationGroupPasswordURI(request: CreateAuthorizationGroupPasswordRequest, options?: T): string;
  /** Get the URI of `UpdateAuthorizationGroupPassword` method */
  GetUpdateAuthorizationGroupPasswordURI(request: UpdateAuthorizationGroupPasswordRequest, options?: T): string;
  /** Get the URI of `DeleteAuthorizationGroupPassword` method */
  GetDeleteAuthorizationGroupPasswordURI(request: DeleteAuthorizationGroupPasswordRequest, options?: T): string;
  /** Get the URI of `GetAuthorizationGroupPassword` method */
  GetGetAuthorizationGroupPasswordURI(request: GetAuthorizationGroupPasswordRequest, options?: T): string;
  /** Get the URI of `ListAuthorizationGroupPasswords` method */
  GetListAuthorizationGroupPasswordsURI(request: ListAuthorizationGroupPasswordsRequest, options?: T): string;
  /** Get the URI of `ListAuthorizationGroupPasswordIds` method */
  GetListAuthorizationGroupPasswordIdsURI(request: ListAuthorizationGroupPasswordIdsRequest, options?: T): string;
}

export function getAuthorizationGroupPasswordsURIs<T = unknown>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlerOptions: {
    mapStringify?: (map: Record<string, unknown>) => string;
  } = {},
): AuthorizationGroupPasswordsURIs<T> {
  return {
    GetCreateAuthorizationGroupPasswordURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `authorization_group_passwords`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetUpdateAuthorizationGroupPasswordURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `authorization_group_passwords/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetDeleteAuthorizationGroupPasswordURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `authorization_group_passwords/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetGetAuthorizationGroupPasswordURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `authorization_group_passwords/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetListAuthorizationGroupPasswordsURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `authorization_group_passwords`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      if (request.filter_by?.ids) {
        request.filter_by.ids.forEach((x) => {
          queryParams.push(`filter_by.ids=${encodeURIComponent(x.toString())}`);
        })
      }
      if (request.filter_by?.subscriber_ids) {
        request.filter_by.subscriber_ids.forEach((x) => {
          queryParams.push(`filter_by.subscriber_ids=${encodeURIComponent(x.toString())}`);
        })
      }
      if (request.pagination?.offset) {
        queryParams.push(`pagination.offset=${encodeURIComponent(request.pagination.offset.toString())}`);
      }
      if (request.pagination?.limit) {
        queryParams.push(`pagination.limit=${encodeURIComponent(request.pagination.limit.toString())}`);
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetListAuthorizationGroupPasswordIdsURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `authorization_group_password_ids`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      if (request.filter_by?.ids) {
        request.filter_by.ids.forEach((x) => {
          queryParams.push(`filter_by.ids=${encodeURIComponent(x.toString())}`);
        })
      }
      if (request.filter_by?.subscriber_ids) {
        request.filter_by.subscriber_ids.forEach((x) => {
          queryParams.push(`filter_by.subscriber_ids=${encodeURIComponent(x.toString())}`);
        })
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
  };
}
export function createAuthorizationGroupPasswordsClient<T = unknown>(
  handler: RequestHandler<T>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlerOptions: {
    mapStringify?: (map: Record<string, unknown>) => string;
  } = {},
): AuthorizationGroupPasswords<T> {
  const uris = getAuthorizationGroupPasswordsURIs<T>(handlerOptions);
  return {
    CreateAuthorizationGroupPassword(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetCreateAuthorizationGroupPasswordURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "POST",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroupPasswords",
        method: "CreateAuthorizationGroupPassword",
      }) as Promise<CreateAuthorizationGroupPasswordResponse>;
    },
    UpdateAuthorizationGroupPassword(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetUpdateAuthorizationGroupPasswordURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "PUT",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroupPasswords",
        method: "UpdateAuthorizationGroupPassword",
      }) as Promise<UpdateAuthorizationGroupPasswordResponse>;
    },
    DeleteAuthorizationGroupPassword(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetDeleteAuthorizationGroupPasswordURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "DELETE",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroupPasswords",
        method: "DeleteAuthorizationGroupPassword",
      }) as Promise<DeleteAuthorizationGroupPasswordResponse>;
    },
    GetAuthorizationGroupPassword(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetGetAuthorizationGroupPasswordURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroupPasswords",
        method: "GetAuthorizationGroupPassword",
      }) as Promise<GetAuthorizationGroupPasswordResponse>;
    },
    ListAuthorizationGroupPasswords(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetListAuthorizationGroupPasswordsURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroupPasswords",
        method: "ListAuthorizationGroupPasswords",
      }) as Promise<ListAuthorizationGroupPasswordsResponse>;
    },
    ListAuthorizationGroupPasswordIds(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetListAuthorizationGroupPasswordIdsURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "AuthorizationGroupPasswords",
        method: "ListAuthorizationGroupPasswordIds",
      }) as Promise<ListAuthorizationGroupPasswordIdsResponse>;
    },
  };
}
export interface Devices<T = unknown> {
  /**
   * Allows users to create registered devices.
   * Registered devices are devices which once created are active and
   * potentially have a set expiry date.
   */
  CreateRegisteredDevice(request: CreateRegisteredDeviceRequest, options?: T): Promise<CreateRegisteredDeviceResponse>;
  /**
   * Allows users to create pre-provisioned devices.
   * Pre-provisioned devices are devices which are created but remain inactive
   * until they are activated by a user's first successful login, at which
   * point the expiry date is calculated (provided here is a "expires after"
   * entry).
   * NOTE: devices of this type can only gain access to the system if they come
   * through a secure channel like TLS or mTLS, in other words, if the URL
   * contains "https://" at the start.
   */
  CreatePreProvisionedDevice(request: CreatePreProvisionedDeviceRequest, options?: T): Promise<CreatePreProvisionedDeviceResponse>;
  /**
   * Allows users to update a registered device.
   * Read the "Create Registered Device" API for details as to what a
   * "registered" device is.
   */
  UpdateRegisteredDevice(request: UpdateRegisteredDeviceRequest, options?: T): Promise<UpdateRegisteredDeviceResponse>;
  /**
   * Allows users to update a pre-provisioned device.
   * Read the "Create Pre-Provisioned Device" API for details as to what a
   * "pre-provisioned" device is.
   */
  UpdatePreProvisionedDevice(request: UpdatePreProvisionedDeviceRequest, options?: T): Promise<UpdatePreProvisionedDeviceResponse>;
  /**
   * Allows users to update an auto-registered device.
   * Auto-registered devices are essentially "registered" devices which are
   * created automatically by successfully logging in a new device that is part
   * of an "Authorization Group" via mTLS channel. To help explain, say that a
   * subscriber which has registered an authorization group "subx" with an
   * authorization group password of "my-secret" logs in via the mTLS endpoint
   * using username "subx@my-device-name" with password "my-secret", the device
   * will automatically be created on its first login.
   * NOTE: auto-registered device can only login via the mTLS endpoint, even
   * after they are registered with Skylark.
   */
  UpdateAutoRegisteredDevice(request: UpdateAutoRegisteredDeviceRequest, options?: T): Promise<UpdateAutoRegisteredDeviceResponse>;
  /**
   * Allows users to delete any type of device.
   */
  DeleteDevice(request: DeleteDeviceRequest, options?: T): Promise<DeleteDeviceResponse>;
  /**
   * Allows users to obtain details about a particular device.
   */
  GetDevice(request: GetDeviceRequest, options?: T): Promise<GetDeviceResponse>;
  /**
   * Allows users to obtain details about a set of devices.
   * NOTE: the API does put a cap on the number of results returned. To obtain
   * an uncapped list, please use the "List Device Usernames" API.
   */
  ListDevices(request: ListDevicesRequest, options?: T): Promise<ListDevicesResponse>;
  /**
   * Allows users to obtain the usernames of a set of devices.
   * NOTE: API returns only the device usernames that satisfies the filtering
   * criteria. Users can use the "List Devices" API to obtain the details for
   * each device. Unlike the "List Devices" API, this API does not cap on the
   * number of results, this API should primarily be used for initiating a bulk
   * API calls.
   */
  ListDeviceUsernames(request: ListDeviceUsernamesRequest, options?: T): Promise<ListDeviceUsernamesResponse>;
}

/** The URIs for Devices */
export interface DevicesURIs<T = unknown> {
  /** Get the URI of `CreateRegisteredDevice` method */
  GetCreateRegisteredDeviceURI(request: CreateRegisteredDeviceRequest, options?: T): string;
  /** Get the URI of `CreatePreProvisionedDevice` method */
  GetCreatePreProvisionedDeviceURI(request: CreatePreProvisionedDeviceRequest, options?: T): string;
  /** Get the URI of `UpdateRegisteredDevice` method */
  GetUpdateRegisteredDeviceURI(request: UpdateRegisteredDeviceRequest, options?: T): string;
  /** Get the URI of `UpdatePreProvisionedDevice` method */
  GetUpdatePreProvisionedDeviceURI(request: UpdatePreProvisionedDeviceRequest, options?: T): string;
  /** Get the URI of `UpdateAutoRegisteredDevice` method */
  GetUpdateAutoRegisteredDeviceURI(request: UpdateAutoRegisteredDeviceRequest, options?: T): string;
  /** Get the URI of `DeleteDevice` method */
  GetDeleteDeviceURI(request: DeleteDeviceRequest, options?: T): string;
  /** Get the URI of `GetDevice` method */
  GetGetDeviceURI(request: GetDeviceRequest, options?: T): string;
  /** Get the URI of `ListDevices` method */
  GetListDevicesURI(request: ListDevicesRequest, options?: T): string;
  /** Get the URI of `ListDeviceUsernames` method */
  GetListDeviceUsernamesURI(request: ListDeviceUsernamesRequest, options?: T): string;
}

export function getDevicesURIs<T = unknown>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlerOptions: {
    mapStringify?: (map: Record<string, unknown>) => string;
  } = {},
): DevicesURIs<T> {
  return {
    GetCreateRegisteredDeviceURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `registered_devices`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetCreatePreProvisionedDeviceURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `preprovisioned_devices`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetUpdateRegisteredDeviceURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.username) {
        throw new Error("missing required field request.username");
      }
      const path = `registered_devices/${request.username}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetUpdatePreProvisionedDeviceURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.username) {
        throw new Error("missing required field request.username");
      }
      const path = `preprovisioned_devices/${request.username}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetUpdateAutoRegisteredDeviceURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.username) {
        throw new Error("missing required field request.username");
      }
      const path = `auto_registered_devices/${request.username}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetDeleteDeviceURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.username) {
        throw new Error("missing required field request.username");
      }
      const path = `devices/${request.username}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetGetDeviceURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.username) {
        throw new Error("missing required field request.username");
      }
      const path = `devices/${request.username}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetListDevicesURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `devices`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetListDeviceUsernamesURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `device_usernames`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      if (request.filter_by?.usernames) {
        request.filter_by.usernames.forEach((x) => {
          queryParams.push(`filter_by.usernames=${encodeURIComponent(x.toString())}`);
        })
      }
      if (request.filter_by?.subscriber_ids) {
        request.filter_by.subscriber_ids.forEach((x) => {
          queryParams.push(`filter_by.subscriber_ids=${encodeURIComponent(x.toString())}`);
        })
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
  };
}
export function createDevicesClient<T = unknown>(
  handler: RequestHandler<T>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlerOptions: {
    mapStringify?: (map: Record<string, unknown>) => string;
  } = {},
): Devices<T> {
  const uris = getDevicesURIs<T>(handlerOptions);
  return {
    CreateRegisteredDevice(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetCreateRegisteredDeviceURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "POST",
        body,
        ...(options as T),
      }, {
        service: "Devices",
        method: "CreateRegisteredDevice",
      }) as Promise<CreateRegisteredDeviceResponse>;
    },
    CreatePreProvisionedDevice(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetCreatePreProvisionedDeviceURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "POST",
        body,
        ...(options as T),
      }, {
        service: "Devices",
        method: "CreatePreProvisionedDevice",
      }) as Promise<CreatePreProvisionedDeviceResponse>;
    },
    UpdateRegisteredDevice(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetUpdateRegisteredDeviceURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "PUT",
        body,
        ...(options as T),
      }, {
        service: "Devices",
        method: "UpdateRegisteredDevice",
      }) as Promise<UpdateRegisteredDeviceResponse>;
    },
    UpdatePreProvisionedDevice(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetUpdatePreProvisionedDeviceURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "PUT",
        body,
        ...(options as T),
      }, {
        service: "Devices",
        method: "UpdatePreProvisionedDevice",
      }) as Promise<UpdatePreProvisionedDeviceResponse>;
    },
    UpdateAutoRegisteredDevice(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetUpdateAutoRegisteredDeviceURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "PUT",
        body,
        ...(options as T),
      }, {
        service: "Devices",
        method: "UpdateAutoRegisteredDevice",
      }) as Promise<UpdateAutoRegisteredDeviceResponse>;
    },
    DeleteDevice(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetDeleteDeviceURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "DELETE",
        body,
        ...(options as T),
      }, {
        service: "Devices",
        method: "DeleteDevice",
      }) as Promise<DeleteDeviceResponse>;
    },
    GetDevice(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetGetDeviceURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "Devices",
        method: "GetDevice",
      }) as Promise<GetDeviceResponse>;
    },
    ListDevices(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetListDevicesURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "POST",
        body,
        ...(options as T),
      }, {
        service: "Devices",
        method: "ListDevices",
      }) as Promise<ListDevicesResponse>;
    },
    ListDeviceUsernames(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetListDeviceUsernamesURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "Devices",
        method: "ListDeviceUsernames",
      }) as Promise<ListDeviceUsernamesResponse>;
    },
  };
}
export interface Subscribers<T = unknown> {
  /**
   * Allows users to obtain details about a particular subscriber.
   */
  GetSubscriber(request: GetSubscriberRequest, options?: T): Promise<GetSubscriberResponse>;
  /**
   * Allows users to obtain details about a set of subscribers.
   * NOTE: the API does put a cap on the number of results returned. To obtain
   * an uncapped list, please use the "List Subscribers IDs" API.
   */
  ListSubscribers(request: ListSubscribersRequest, options?: T): Promise<ListSubscribersResponse>;
  /**
   * Allows users to obtain the IDs of a set of subscribers.
   * NOTE: API returns only the subscriber IDs that satisfies the filtering
   * criteria. Users can use the "List Subscribers" API to obtain the details
   * for each subscriber. Unlike the "List Subscribers" API, this API does not
   * cap on the number of results, this API should primarily be used for
   * initiating a bulk API calls.
   */
  ListSubscriberIds(request: ListSubscriberIdsRequest, options?: T): Promise<ListSubscriberIdsResponse>;
  /**
   * Allows users to create a subscriber.
   */
  CreateSubscriber(request: CreateSubscriberRequest, options?: T): Promise<CreateSubscriberResponse>;
  /**
   * Allows users to update a subscriber.
   */
  UpdateSubscriber(request: UpdateSubscriberRequest, options?: T): Promise<UpdateSubscriberResponse>;
  /**
   * Allows users to delete a subscriber.
   */
  DeleteSubscriber(request: DeleteSubscriberRequest, options?: T): Promise<DeleteSubscriberResponse>;
}

/** The URIs for Subscribers */
export interface SubscribersURIs<T = unknown> {
  /** Get the URI of `GetSubscriber` method */
  GetGetSubscriberURI(request: GetSubscriberRequest, options?: T): string;
  /** Get the URI of `ListSubscribers` method */
  GetListSubscribersURI(request: ListSubscribersRequest, options?: T): string;
  /** Get the URI of `ListSubscriberIds` method */
  GetListSubscriberIdsURI(request: ListSubscriberIdsRequest, options?: T): string;
  /** Get the URI of `CreateSubscriber` method */
  GetCreateSubscriberURI(request: CreateSubscriberRequest, options?: T): string;
  /** Get the URI of `UpdateSubscriber` method */
  GetUpdateSubscriberURI(request: UpdateSubscriberRequest, options?: T): string;
  /** Get the URI of `DeleteSubscriber` method */
  GetDeleteSubscriberURI(request: DeleteSubscriberRequest, options?: T): string;
}

export function getSubscribersURIs<T = unknown>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlerOptions: {
    mapStringify?: (map: Record<string, unknown>) => string;
  } = {},
): SubscribersURIs<T> {
  return {
    GetGetSubscriberURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `subscribers/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetListSubscribersURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `subscribers`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      if (request.filter_by?.ids) {
        request.filter_by.ids.forEach((x) => {
          queryParams.push(`filter_by.ids=${encodeURIComponent(x.toString())}`);
        })
      }
      if (request.pagination?.offset) {
        queryParams.push(`pagination.offset=${encodeURIComponent(request.pagination.offset.toString())}`);
      }
      if (request.pagination?.limit) {
        queryParams.push(`pagination.limit=${encodeURIComponent(request.pagination.limit.toString())}`);
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetListSubscriberIdsURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `subscriber_ids`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      if (request.filter_by?.ids) {
        request.filter_by.ids.forEach((x) => {
          queryParams.push(`filter_by.ids=${encodeURIComponent(x.toString())}`);
        })
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetCreateSubscriberURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `subscribers`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetUpdateSubscriberURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `subscribers/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetDeleteSubscriberURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `subscribers/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
  };
}
export function createSubscribersClient<T = unknown>(
  handler: RequestHandler<T>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlerOptions: {
    mapStringify?: (map: Record<string, unknown>) => string;
  } = {},
): Subscribers<T> {
  const uris = getSubscribersURIs<T>(handlerOptions);
  return {
    GetSubscriber(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetGetSubscriberURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "Subscribers",
        method: "GetSubscriber",
      }) as Promise<GetSubscriberResponse>;
    },
    ListSubscribers(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetListSubscribersURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "Subscribers",
        method: "ListSubscribers",
      }) as Promise<ListSubscribersResponse>;
    },
    ListSubscriberIds(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetListSubscriberIdsURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "Subscribers",
        method: "ListSubscriberIds",
      }) as Promise<ListSubscriberIdsResponse>;
    },
    CreateSubscriber(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetCreateSubscriberURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "POST",
        body,
        ...(options as T),
      }, {
        service: "Subscribers",
        method: "CreateSubscriber",
      }) as Promise<CreateSubscriberResponse>;
    },
    UpdateSubscriber(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetUpdateSubscriberURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "PUT",
        body,
        ...(options as T),
      }, {
        service: "Subscribers",
        method: "UpdateSubscriber",
      }) as Promise<UpdateSubscriberResponse>;
    },
    DeleteSubscriber(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetDeleteSubscriberURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "DELETE",
        body,
        ...(options as T),
      }, {
        service: "Subscribers",
        method: "DeleteSubscriber",
      }) as Promise<DeleteSubscriberResponse>;
    },
  };
}
export interface X509Certificates<T = unknown> {
  /**
   * Allows users to create X.509 certificates.
   * X.509 certificates are needed for any mTLS connection with Skylark. User's
   * that wish to auto-register there devices that are part of an authorization
   * group must register their X.509 certificate with us before attempting to
   * login via the mTLS endpoints.
   * NOTE: successful requests here take up to a minute to take effect when
   * authenticating client device via mTLS.
   */
  CreateX509Certificate(request: CreateX509CertificateRequest, options?: T): Promise<CreateX509CertificateResponse>;
  /**
   * Allows users to update X.509 certificates.
   * Read the "Create X.509 Certificate" API for details as to what it's used
   * for.
   * NOTE: successful requests here take up to a minute to take effect when
   * authenticating client device via mTLS.
   */
  UpdateX509Certificate(request: UpdateX509CertificateRequest, options?: T): Promise<UpdateX509CertificateResponse>;
  /**
   * Allows users to delete a X.509 certificate.
   * NOTE: successful requests here take up to a minute to take effect when
   * authenticating client device via mTLS.
   */
  DeleteX509Certificate(request: DeleteX509CertificateRequest, options?: T): Promise<DeleteX509CertificateResponse>;
  /**
   * Allows users to obtain details about a particular X.509 certificate.
   */
  GetX509Certificate(request: GetX509CertificateRequest, options?: T): Promise<GetX509CertificateResponse>;
  /**
   * Allows users to obtain details about a set of X.509 certificates.
   * NOTE: the API does put a cap on the number of results returned. To obtain
   * an uncapped list, please use the "List X.509 certificate IDs" API.
   */
  ListX509Certificates(request: ListX509CertificatesRequest, options?: T): Promise<ListX509CertificatesResponse>;
  /**
   * Allows users to obtain the IDs of a set of X.509 certificate IDs.
   * NOTE: API returns only the X.509 certificate IDs that satisfies the
   * filtering criteria. Users can use the "List X.509 Certificate" API to
   * obtain the details for each item. Unlike the "List X.509 Certificate" API,
   * this API does not cap on the number of results, this API should primarily
   * be used for initiating a bulk API calls.
   */
  ListX509CertificateIds(request: ListX509CertificateIdsRequest, options?: T): Promise<ListX509CertificateIdsResponse>;
  /**
   * Allows users to request a X.509 certificate signed by Swift Navigation's
   * own private Certificate Authority (CA).
   * Partners that wish to avoid going through third party CA can instead
   * request a certificate signed by us. They can than pass the results back
   * into the "Create X.509 Certificate" API and have it registered with a
   * subscriber.
   */
  IssueX509Certificate(request: IssueX509CertificateRequest, options?: T): Promise<IssueX509CertificateResponse>;
}

/** The URIs for X509Certificates */
export interface X509CertificatesURIs<T = unknown> {
  /** Get the URI of `CreateX509Certificate` method */
  GetCreateX509CertificateURI(request: CreateX509CertificateRequest, options?: T): string;
  /** Get the URI of `UpdateX509Certificate` method */
  GetUpdateX509CertificateURI(request: UpdateX509CertificateRequest, options?: T): string;
  /** Get the URI of `DeleteX509Certificate` method */
  GetDeleteX509CertificateURI(request: DeleteX509CertificateRequest, options?: T): string;
  /** Get the URI of `GetX509Certificate` method */
  GetGetX509CertificateURI(request: GetX509CertificateRequest, options?: T): string;
  /** Get the URI of `ListX509Certificates` method */
  GetListX509CertificatesURI(request: ListX509CertificatesRequest, options?: T): string;
  /** Get the URI of `ListX509CertificateIds` method */
  GetListX509CertificateIdsURI(request: ListX509CertificateIdsRequest, options?: T): string;
  /** Get the URI of `IssueX509Certificate` method */
  GetIssueX509CertificateURI(request: IssueX509CertificateRequest, options?: T): string;
}

export function getX509CertificatesURIs<T = unknown>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlerOptions: {
    mapStringify?: (map: Record<string, unknown>) => string;
  } = {},
): X509CertificatesURIs<T> {
  return {
    GetCreateX509CertificateURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `x509_certificates`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetUpdateX509CertificateURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `x509_certificates/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetDeleteX509CertificateURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `x509_certificates/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetGetX509CertificateURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `x509_certificates/${request.id}`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetListX509CertificatesURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `x509_certificates`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      if (request.filter_by?.ids) {
        request.filter_by.ids.forEach((x) => {
          queryParams.push(`filter_by.ids=${encodeURIComponent(x.toString())}`);
        })
      }
      if (request.filter_by?.subscriber_ids) {
        request.filter_by.subscriber_ids.forEach((x) => {
          queryParams.push(`filter_by.subscriber_ids=${encodeURIComponent(x.toString())}`);
        })
      }
      if (request.pagination?.offset) {
        queryParams.push(`pagination.offset=${encodeURIComponent(request.pagination.offset.toString())}`);
      }
      if (request.pagination?.limit) {
        queryParams.push(`pagination.limit=${encodeURIComponent(request.pagination.limit.toString())}`);
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetListX509CertificateIdsURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `x509_certificate_ids`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      if (request.filter_by?.ids) {
        request.filter_by.ids.forEach((x) => {
          queryParams.push(`filter_by.ids=${encodeURIComponent(x.toString())}`);
        })
      }
      if (request.filter_by?.subscriber_ids) {
        request.filter_by.subscriber_ids.forEach((x) => {
          queryParams.push(`filter_by.subscriber_ids=${encodeURIComponent(x.toString())}`);
        })
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
    GetIssueX509CertificateURI(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `issue_x509_certificate`; // eslint-disable-line quotes
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return uri;
    },
  };
}
export function createX509CertificatesClient<T = unknown>(
  handler: RequestHandler<T>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlerOptions: {
    mapStringify?: (map: Record<string, unknown>) => string;
  } = {},
): X509Certificates<T> {
  const uris = getX509CertificatesURIs<T>(handlerOptions);
  return {
    CreateX509Certificate(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetCreateX509CertificateURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "POST",
        body,
        ...(options as T),
      }, {
        service: "X509Certificates",
        method: "CreateX509Certificate",
      }) as Promise<CreateX509CertificateResponse>;
    },
    UpdateX509Certificate(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetUpdateX509CertificateURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "PUT",
        body,
        ...(options as T),
      }, {
        service: "X509Certificates",
        method: "UpdateX509Certificate",
      }) as Promise<UpdateX509CertificateResponse>;
    },
    DeleteX509Certificate(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetDeleteX509CertificateURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "DELETE",
        body,
        ...(options as T),
      }, {
        service: "X509Certificates",
        method: "DeleteX509Certificate",
      }) as Promise<DeleteX509CertificateResponse>;
    },
    GetX509Certificate(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetGetX509CertificateURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "X509Certificates",
        method: "GetX509Certificate",
      }) as Promise<GetX509CertificateResponse>;
    },
    ListX509Certificates(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetListX509CertificatesURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "X509Certificates",
        method: "ListX509Certificates",
      }) as Promise<ListX509CertificatesResponse>;
    },
    ListX509CertificateIds(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetListX509CertificateIdsURI(request, options);
      const body = null;
      return handler({
        path: uri,
        method: "GET",
        body,
        ...(options as T),
      }, {
        service: "X509Certificates",
        method: "ListX509CertificateIds",
      }) as Promise<ListX509CertificateIdsResponse>;
    },
    IssueX509Certificate(request, options) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const uri = uris.GetIssueX509CertificateURI(request, options);
      const body = JSON.stringify(request);
      return handler({
        path: uri,
        method: "POST",
        body,
        ...(options as T),
      }, {
        service: "X509Certificates",
        method: "IssueX509Certificate",
      }) as Promise<IssueX509CertificateResponse>;
    },
  };
}

// @@protoc_insertion_point(typescript-http-eof)
