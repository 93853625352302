import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import AdminTabs from "@/components/AdminTabs"
import CertificateForm from "@/components/forms/CertificateForm"

export const Route = createFileRoute(
  "/_auth/administration/certificates/create",
)({
  component: () => <CreateCertificatePage />,
  staticData: {
    title: "Administration: Add Certificate",
  },
})

const CreateCertificatePage = () => {
  return (
    <>
      <AdminTabs />
      <CertificateForm create={true} />
    </>
  )
}

export default CreateCertificatePage
