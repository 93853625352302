import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import PartnerForm from "@/components/forms/PartnerForm"
import { useAuth } from "@/contexts/auth"
import { hasPermission } from "@/utils/rbac"

export const Route = createFileRoute("/_auth/partners/create")({
  component: () => <CreatePartnerPage />,
  staticData: {
    title: "Create Partner",
  },
})

const CreatePartnerPage = () => {
  const { metadataPublic } = useAuth()

  if (metadataPublic == null) {
    return <div>Loading...</div>
  }

  if (!hasPermission(metadataPublic, "managePartners")) {
    return <div>Unauthorized</div>
  }

  return <PartnerForm create={true} />
}
