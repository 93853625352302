import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import AdminTabs from "@/components/AdminTabs"
import JsonPrettyPrint from "@/components/JsonPrettyPrint"
import LoadingTable from "@/components/tables/LoadingTable"
import UsersTable from "@/components/tables/UsersTable"
import { useUsers } from "@/hooks"

export const Route = createFileRoute("/_auth/administration/users/")({
  component: () => <AdminUsersPage />,
  staticData: {
    title: "Administration: Users",
  },
})

const AdminUsersPage = () => {
  const { name, partnerId }: { name: string; partnerId: string } =
    Route.useSearch()
  const { data: users, isLoading, error } = useUsers(partnerId, name)

  if (isLoading)
    return (
      <>
        <AdminTabs />
        <LoadingTable rows={5} columns={5} />
      </>
    )

  if (error) {
    return (
      <>
        <AdminTabs />
        <JsonPrettyPrint json={error} />
      </>
    )
  }

  return (
    <>
      <AdminTabs />
      <UsersTable users={users} linkTo={Route.fullPath} />
    </>
  )
}
