import { Field, Input, Label } from "@headlessui/react"
import { UiNode } from "@ory/client"
import { useForm } from "@tanstack/react-form"
import {
  Link,
  createFileRoute,
  redirect,
  useNavigate,
  useRouterState,
} from "@tanstack/react-router"
import { AxiosError } from "axios"
import * as React from "react"
import { z } from "zod"

import Button from "@/components/Button"
import FieldInfo from "@/components/FieldInfo"
import SwiftLogo from "@/components/SwiftLogo"
import APIConfig from "@/services/config"
import { orySDK } from "@/services/ory"
import { UserMetadataPublic } from "@/services/types"

const fallback = "/devices" as const

export const Route = createFileRoute("/login")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
    flow: z.string().optional().catch(""),
  }),
  beforeLoad: async ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: search.redirect || fallback })
    }
  },
  component: () => <LoginComponent />,
})

const LoginComponent = () => {
  const navigate = useNavigate()
  const isLoading = useRouterState({ select: (s) => s.isLoading })

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [oidcIsSubmitting, setOidcIsSubmitting] = React.useState(false)
  const [flow, setFlow] = React.useState<any | undefined>(undefined)
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>()

  const search = Route.useSearch()

  const flowId = search.flow

  const isLoggingIn = isLoading || isSubmitting

  React.useEffect(() => {
    setErrorMessage(undefined)
    if (flow !== undefined) {
      // flow exists already
      return
    }

    // if ?flow is in the url, we fetch it
    if (flowId) {
      orySDK
        .getLoginFlow({ id: flowId })
        .then(({ data }) => setFlow(data))
        .catch((err: unknown) => {
          if (err instanceof AxiosError) {
            // If the flow is no longer valid (410) or can't be found (404), request a new flow
            if (err.response?.status === 410 || err.response?.status === 404) {
              window.location.replace(
                `${APIConfig.kratosApi}/self-service/login/browser`,
              )
            }
          } else {
            setErrorMessage("An error occurred, try again later")
          }
        })
      return
    }

    // otherwise we initialize it
    orySDK
      .createBrowserLoginFlow({
        refresh: true,
      })
      .then(({ data }) => setFlow(data))
      .catch((err) => {
        console.error(err)
      })
  }, [flow, setErrorMessage])

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    onSubmit: async ({ value }) => {
      setIsSubmitting(true)
      setErrorMessage(undefined)
      try {
        if (!flow) {
          //If flow is not available, redirect to login
          redirect({ to: "/login" })
          return
        }
        if (value.email === "" || value.password === "") {
          return
        }

        orySDK
          .updateLoginFlow({
            flow: flow.id,
            updateLoginFlowBody: {
              identifier: value.email,
              password: value.password,
              method: "password",
              csrf_token: flow.ui.nodes.find(
                (node: UiNode & { attributes: { name: string } }) =>
                  node.attributes.name === "csrf_token",
              ).attributes.value,
            },
          })
          .then(({ data }) => {
            const { role } = data.session.identity
              ?.metadata_public as UserMetadataPublic

            if (role === "guest") {
              navigate({ to: "/coverage-map" })
              return
            }
            navigate({ to: search.redirect || fallback })
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              setErrorMessage("Invalid Email and/or Password")
            } else {
              setErrorMessage("Error, please try again")
            }
          })
      } catch (err) {
        console.error(err)
      } finally {
        setIsSubmitting(false)
      }
    },
  })

  const swiftLogin = async () => {
    setOidcIsSubmitting(true)
    setErrorMessage(undefined)
    try {
      await orySDK.updateLoginFlow({
        flow: flow.id,
        updateLoginFlowBody: {
          provider: "swift",
          method: "oidc",
          csrf_token: flow.ui.nodes.find(
            (node: UiNode & { attributes: { name: string } }) =>
              node.attributes.name === "csrf_token",
          ).attributes.value,
        },
      })
    } catch (err: any) {
      if (err.response.status === 422) {
        return window.location.replace(err.response.data.redirect_browser_to)
      }

      setErrorMessage("An error occurred, try again later")
      setOidcIsSubmitting(false)
    }
  }

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="flex items-center justify-center">
          <SwiftLogo />
        </div>
        <h2 className="mt-5 text-center text-2xl leading-9 tracking-tight text-gray-800">
          Skylark Partner Portal Login
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          className="space-y-6"
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            form.handleSubmit()
          }}
        >
          <form.Field
            name="email"
            children={(field) => {
              return (
                <>
                  <Field className="mb-5">
                    <Label
                      htmlFor={field.name}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </Label>
                    <Input
                      type="email"
                      autoComplete="on"
                      tabIndex={1}
                      id={field.name}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                    <FieldInfo field={field} />
                  </Field>
                </>
              )
            }}
          />

          <form.Field
            name="password"
            children={(field) => {
              return (
                <Field>
                  <div className="flex items-center justify-between">
                    <Label
                      htmlFor={field.name}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </Label>
                    <Link
                      tabIndex={3}
                      to="/recovery"
                      className="text-sm hover:text-indigo-500"
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <Input
                    tabIndex={2}
                    autoComplete="current-password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    id={field.name}
                    name={field.name}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    type="password"
                    value={field.state.value}
                  />
                </Field>
              )
            }}
          />
          {errorMessage && (
            <div className="text-red-500 text-sm">{errorMessage}</div>
          )}
          <Button type="submit" color="primary">
            {isLoggingIn ? "Loading..." : "Sign in"}
          </Button>
        </form>
        <form className="mt-6">
          <Button type="button" color="primary" onClick={swiftLogin}>
            {oidcIsSubmitting ? "Loading..." : "Okta Login"}
          </Button>
        </form>
        <section className="text-sm text-center mt-6">
          Don't have an account?{" "}
          <a
            className="hover:text-indigo-500 mr-1"
            href="mailto:support@swiftnav.com"
          >
            Contact Us
          </a>
        </section>
      </div>
    </div>
  )
}
