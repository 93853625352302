import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import SubscriberForm from "@/components/forms/SubscriberForm"

export const Route = createFileRoute("/_auth/subscribers/create")({
  component: () => <CreateSubscriberPage />,
  staticData: {
    title: "Create Subscriber",
  },
})

const CreateSubscriberPage = () => {
  return <SubscriberForm create={true} />
}
