import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import DeviceForm from "@/components/forms/DeviceForm"

export const Route = createFileRoute("/_auth/devices/create")({
  component: () => <CreateDevicePage />,
  staticData: {
    title: "Create Device",
  },
})

const CreateDevicePage = () => {
  return <DeviceForm create={true} />
}
