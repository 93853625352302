import { useForm } from "@tanstack/react-form"
import { useMutation, useQuery } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"
import React, { Fragment, useState } from "react"
import { toast } from "react-hot-toast"

import AdminTabs from "@/components/AdminTabs"
import ApiKeyCreateModal from "@/components/dialogs/ApiKeyCreateModal"
import ApiKeyDeleteModal from "@/components/dialogs/ApiKeyDeleteModal"
import ApiKeyTable from "@/components/tables/ApiKeyTable"
import LoadingTable from "@/components/tables/LoadingTable"
import { useAuth } from "@/contexts/auth"
import { createAPIKey, deleteApiKey, getApiKeys } from "@/services/portalApi"
import { hasPermission } from "@/utils/rbac"

export const Route = createFileRoute("/_auth/administration/apikeys/")({
  component: () => <AdminApiKeysPage />,
  staticData: {
    title: "Administration: API Keys",
  },
})

const ModalState = {
  hidden: "hidden",
  visible: "visible",
  response: "response",
}

const AdminApiKeysPage = () => {
  const { metadataPublic } = useAuth()
  const [showModal, setShowModal] = useState("hidden")
  const [apiKeyValue, setApiKeyValue] = useState("")
  const [keyToDelete, setKeyToDelete] = useState<string | null>(null)

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["apiKeys"],
    queryFn: getApiKeys,
  })

  const createApiKeyMutation = useMutation({
    mutationFn: createAPIKey,
    onSuccess: (data) => {
      setApiKeyValue(data.secret)
      refetch()
      setShowModal(ModalState.response)
    },
    onError: (err: any) => {
      const fieldMeta = form.getFieldMeta("name")
      form.setFieldMeta("name", {
        ...fieldMeta!,
        errorMap: { onChange: err.response.data.message },
      })
      toast.error(err.response.data.message)
    },
  })

  const deleteApiKeyMutation = useMutation({
    mutationFn: deleteApiKey,
    onSuccess: () => {
      refetch()
      setKeyToDelete(null)
    },
    onError: (err: any) => {
      toast.error(err.response.data.message)
    },
  })

  const removeApiKey = () => {
    if (keyToDelete) {
      deleteApiKeyMutation.mutate(keyToDelete)
    }
  }

  const form = useForm({
    defaultValues: {
      name: "",
    },
    validators: {
      onSubmit: () => {
        if (!hasPermission(metadataPublic, "manageApiKeys")) {
          return "You do not have permission to manage API keys"
        }
        return null
      },
    },
    onSubmit: ({ value }) => createApiKeyMutation.mutate(value),
  })

  const handleModalState = (shouldOpen: boolean) => {
    form.reset()
    if (shouldOpen) {
      setShowModal(ModalState.visible)
    } else {
      setShowModal(ModalState.hidden)
    }
  }

  const setShowDeleteModal = (shouldOpen: boolean) => {
    if (!shouldOpen) {
      setKeyToDelete(null)
    }
  }

  if (error) return <div>Error: {error.message}</div>

  if (isLoading) return <LoadingTable rows={5} columns={3} />

  return (
    <Fragment>
      {showModal !== ModalState.hidden && (
        <ApiKeyCreateModal
          setShowModal={handleModalState}
          modalState={showModal}
          form={form}
          apiKeyValue={apiKeyValue}
        />
      )}
      {keyToDelete && (
        <ApiKeyDeleteModal
          setShowModal={setShowDeleteModal}
          onDelete={removeApiKey}
        />
      )}
      <AdminTabs />
      <ApiKeyTable
        apiKeys={data?.apiKeys ?? []}
        setShowModal={handleModalState}
        loading={isLoading}
        onDelete={(apikeyId: string) => setKeyToDelete(apikeyId)}
      />
    </Fragment>
  )
}
