import {
  Description,
  DialogTitle,
  Field,
  Input,
  Label,
  Radio,
  RadioGroup,
} from "@headlessui/react"
import { useForm } from "@tanstack/react-form"
import { useQuery } from "@tanstack/react-query"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createFileRoute, useNavigate, useSearch } from "@tanstack/react-router"
import React from "react"

import { roles } from "./create"
import AdminTabs from "@/components/AdminTabs"
import Button from "@/components/Button"
import FieldInfo from "@/components/FieldInfo"
import FormErrors from "@/components/FormErrors"
import Modal from "@/components/Modal"
import PartnerSelect from "@/components/PartnerSelect"
import { useAuth } from "@/contexts/auth"
import { deleteUser, getUser, updateUser } from "@/services/portalApi"
import { hasPermission } from "@/utils/rbac"

type SearchProps = {
  opid?: string // passing the original partnerId as opid, so that it does not conflict the the partner selector
}

export const Route = createFileRoute("/_auth/administration/users/$userId")({
  component: () => <AdminUsersPage />,
  staticData: {
    title: "Administration: Update User",
  },
  validateSearch: (search: Record<string, unknown>): SearchProps => {
    return {
      opid: search.opid as string,
    }
  },
})

const AdminUsersPage = () => {
  const { userId } = Route.useParams()
  const { data, status } = useQuery({
    queryKey: ["users", userId],
    queryFn: () => getUser(userId),
  })

  if (status !== "success") {
    return (
      <>
        <AdminTabs />
        <p>Loading...</p>
      </>
    )
  }

  return (
    <>
      <AdminTabs />
      <UserForm data={data} />
    </>
  )
}

type UserFormProps = {
  data: any
}

const UserForm: React.FC<UserFormProps> = ({ data }) => {
  const { metadataPublic } = useAuth()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [isWarningOpen, setIsWarningOpen] = React.useState(false)
  const { opid } = useSearch({ strict: false })

  const oidcInputDisabled = "oidc" in data.credentials ? true : false

  const updateMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: (data) => {
      const partnerId = data.metadata_public.org_id

      // invalidate query user id cache
      queryClient.invalidateQueries({ queryKey: ["users", data.id] })
      // invalidate users list with null partnerId object
      queryClient.invalidateQueries({
        queryKey: ["users", "list", {}],
      })
      // invalidate users list original partnerId
      queryClient.invalidateQueries({
        queryKey: ["users", "list", { partnerId: opid }],
      })
      // invalidate users list new partnerId
      queryClient.invalidateQueries({
        queryKey: ["users", "list", { partnerId }],
      })

      return navigate({
        to: "/administration/users",
        search: () => ({
          partnerId,
        }),
      })
    },
    onError: (err) => {
      form.setErrorMap({ onSubmit: err.message })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      // TODO: invalidate query user id cache
      setIsWarningOpen(false)
      return navigate({ to: "/administration/users", search: (prev) => prev })
    },
    onError: (err) => {
      form.setErrorMap({ onSubmit: err.message })
    },
  })

  const form = useForm({
    defaultValues: {
      state: data.state,
      schema_id: data.schema_id,
      traits: {
        name: {
          first: data.traits.name.first,
          last: data.traits.name.last,
        },
        email: data.traits.email,
      },
      metadata_public: {
        org_id: data.metadata_public.org_id,
        role: data.metadata_public.role,
      },
    },
    validators: {
      onSubmit: () => {
        if (!hasPermission(metadataPublic, "manageUsers")) {
          return "You do not have permission to update users"
        }

        return null
      },
    },
    onSubmit: async ({ value }) => {
      updateMutation.mutate({ userId: data.id, payload: value })
    },
  })

  return (
    <>
      <form
        className="max-w-md bg-white p-4 rounded shadow"
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
      >
        {oidcInputDisabled && (
          <div className="text-red-500 text-center text-sm mb-2">
            This user account is managed by an OIDC provider
          </div>
        )}

        <form.Field
          name="traits.name.first"
          children={(field) => {
            return (
              <>
                <Field className="mb-5">
                  <Label
                    htmlFor={field.name}
                    className="block text-sm text-gray-500 mb-2"
                  >
                    First Name
                  </Label>
                  <Input
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    disabled={oidcInputDisabled}
                    id={field.name}
                    name={field.name}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    placeholder="First Name"
                    value={field.state.value}
                  />
                  <FieldInfo field={field} />
                </Field>
              </>
            )
          }}
        />

        <form.Field
          name="traits.name.last"
          children={(field) => {
            return (
              <>
                <Field className="mb-5">
                  <Label
                    htmlFor={field.name}
                    className="block text-sm text-gray-500 mb-2"
                  >
                    Last Name
                  </Label>
                  <Input
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    disabled={oidcInputDisabled}
                    id={field.name}
                    name={field.name}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    placeholder="Last Name"
                    value={field.state.value}
                  />
                  <FieldInfo field={field} />
                </Field>
              </>
            )
          }}
        />

        <form.Field
          name="traits.email"
          children={(field) => {
            return (
              <>
                <Field className="mb-5">
                  <Label
                    htmlFor={field.name}
                    className="block text-sm text-gray-500 mb-2"
                  >
                    Email
                  </Label>
                  <Input
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    disabled={oidcInputDisabled ?? true}
                    id={field.name}
                    name={field.name}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    placeholder="email@address.com"
                    type="email"
                    value={field.state.value}
                  />
                  <FieldInfo field={field} />
                </Field>
              </>
            )
          }}
        />

        {metadataPublic.role === "swift:admin" && (
          <form.Field
            name="metadata_public.org_id"
            children={(field) => {
              return (
                <>
                  <Field className="mb-5">
                    <Label
                      htmlFor="auth-group"
                      className="block text-sm text-gray-500 mb-1.5"
                    >
                      Partner Name
                    </Label>
                    <PartnerSelect
                      defaultOption="Select a Partner"
                      defaultDisabled={true}
                      id={field.name}
                      name={field.name}
                      onChange={(e) => field.handleChange(e.target.value)}
                      value={field.state.value}
                    />
                  </Field>
                  <FieldInfo field={field} />
                </>
              )
            }}
          />
        )}

        <form.Field
          name="metadata_public.role"
          children={(field) => {
            return (
              <>
                <Field className="mb-5">
                  <Label
                    htmlFor={field.name}
                    className="block text-sm text-gray-500 mb-2"
                  >
                    Role / Permissions
                  </Label>
                  <RadioGroup
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    aria-label="Role"
                    onChange={(value) => field.handleChange(value)}
                  >
                    {field.state.value === "swift:admin" && (
                      <Field className="flex items-center gap-2">
                        <Radio
                          disabled={oidcInputDisabled}
                          value={field.state.value}
                          className="group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-blue-500"
                        >
                          <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
                        </Radio>
                        <Label>Swift Admin</Label>
                      </Field>
                    )}
                    {roles.map((role) => {
                      return (
                        <Field
                          key={role.label}
                          className="flex items-center gap-2"
                        >
                          <Radio
                            disabled={oidcInputDisabled}
                            value={role.value}
                            className="group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-blue-500"
                          >
                            <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
                          </Radio>
                          <Label>{role.label}</Label>
                        </Field>
                      )
                    })}
                  </RadioGroup>
                </Field>
              </>
            )
          }}
        />
        {!oidcInputDisabled && (
          <form.Subscribe
            selector={(state) => [
              state.errors,
              state.canSubmit,
              state.isSubmitting,
            ]}
            children={([errors, canSubmit, isSubmitting]) => {
              return (
                <>
                  <FormErrors errors={errors} />
                  <div className="flex justify-end gap-4">
                    <Button
                      type="button"
                      color="danger"
                      disabled={!canSubmit}
                      onClick={(e) => {
                        e.preventDefault()
                        if (!hasPermission(metadataPublic, "manageUsers")) {
                          return form.setErrorMap({
                            onSubmit:
                              "You do not have permission to delete users",
                          })
                        }
                        setIsWarningOpen(true)
                      }}
                    >
                      Delete
                    </Button>

                    <Button type="submit" disabled={!canSubmit} color="blue">
                      {isSubmitting ? "Updating" : "Update"}
                    </Button>
                  </div>
                </>
              )
            }}
          />
        )}
      </form>

      <Modal open={isWarningOpen} onClose={() => setIsWarningOpen(false)}>
        <DialogTitle className="font-bold">Delete account</DialogTitle>
        <Description>
          Are you sure you want to delete the account for {data.traits.email}?
        </Description>
        <div className="flex gap-4">
          <Button color="reset" onClick={() => setIsWarningOpen(false)}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() =>
              deleteMutation.mutate(data.id, {
                onSuccess: () =>
                  queryClient.invalidateQueries({
                    queryKey: ["users", "list"],
                  }),
              })
            }
          >
            Delete
          </Button>
        </div>
      </Modal>
    </>
  )
}
