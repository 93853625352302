import { SettingsFlow } from "@ory/client"
import { createFileRoute } from "@tanstack/react-router"
import React, { useEffect, useState } from "react"
import { z } from "zod"

import PageNotFound from "@/components/PageNotFound"
import TopNav from "@/components/TopNav"
import ProfileForm from "@/components/forms/profile/ProfileForm"
import ResetPasswordForm from "@/components/forms/profile/ResetPasswordForm"
import APIConfig from "@/services/config"
import { orySDK } from "@/services/ory"

export const Route = createFileRoute("/_auth/account/")({
  validateSearch: z.object({
    flow: z.string().optional().catch(""),
  }),
  component: AccountLayout,
  notFoundComponent: () => <PageNotFound />,
})

function AccountLayout() {
  const [flow, setFlow] = useState<SettingsFlow | undefined>(undefined)
  const search = Route.useSearch()

  const flowId = search.flow

  useEffect(() => {
    if (flow !== undefined) {
      // flow exists already
      return
    }

    // if ?flow is in the url, we fetch it
    if (flowId) {
      orySDK
        .getSettingsFlow({ id: flowId })
        .then(({ data }) => {
          setFlow(data)
        })
        .catch((err) => {
          // If the flow is no longer valid, request a new flow
          if (err.response.status === 410) {
            window.location.replace(
              `${APIConfig.kratosApi}/self-service/recovery/browser`,
            )
          }
        })
      return
    }

    // otherwise we initialize it
    orySDK
      .createBrowserSettingsFlow()
      .then(({ data }) => {
        setFlow(data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [flow])

  return (
    <div className="antialiased bg-gray-50">
      <main>
        <TopNav pageTitle="Profile" />
        <ProfileForm flow={flow} />
        <section className="mt-4">
          <h1 className="mb-4">Change Password</h1>
          <ResetPasswordForm flow={flow} />
        </section>
      </main>
    </div>
  )
}
