import { createFileRoute } from "@tanstack/react-router"
import React from "react"

import JsonPrettyPrint from "@/components/JsonPrettyPrint"
import DevicesTable from "@/components/tables/DevicesTable"
import { useDeviceIds, useDevicesQueries, useSubscribers } from "@/hooks"

type SearchProps = {
  offset?: number
  limit?: number
  subscriberId?: string
  name?: string
  partnerId?: string
}

export const Route = createFileRoute("/_auth/devices/")({
  validateSearch: (search: Record<string, unknown>): SearchProps => {
    return {
      offset: search.offset as number,
      limit: search.limit as number,
    }
  },
  component: () => <DevicesPage />,
  staticData: {
    title: "Devices",
  },
})

const DevicesPage = () => {
  const {
    offset = 0,
    limit = 20,
    subscriberId,
    partnerId,
    name,
  } = Route.useSearch()

  const subscribersQuery = useSubscribers(undefined, partnerId)
  let subscriberIds
  if (subscriberId) {
    subscriberIds = [subscriberId]
  } else if (partnerId) {
    subscriberIds = subscribersQuery.data?.map((s) => s.id)
  }

  const {
    data: deviceIds,
    isLoading: deviceIdsLoading,
    error: deviceIdsError,
  } = useDeviceIds(subscriberIds)

  const usernames = deviceIds?.reduce<string[]>(
    (acc, obj) => acc.concat([obj.username]),
    [],
  )

  const {
    data: devices,
    isLoading: devicesLoading,
    error: devicesError,
  } = useDevicesQueries(subscriberIds, usernames)

  if (deviceIdsError || devicesError) {
    return <JsonPrettyPrint json={deviceIdsError || devicesError} />
  }

  const filteredDevices = devices
    .flatMap((r) => r ?? [])
    .filter((device) => {
      // If name is provided, only return devices that include the search value in the name or username
      if (name) {
        return (
          device.name?.toLowerCase().includes(name.toLowerCase()) ||
          device.username.toLowerCase().includes(name.toLowerCase())
        )
      }

      // If no filters are provided, return all devices
      return true
    })

  return (
    <DevicesTable
      devices={filteredDevices.slice(offset, offset + limit)}
      isLoading={deviceIdsLoading || devicesLoading}
      limit={limit}
      linkTo="/devices"
      name={name}
      offset={offset}
      subscriberId={subscriberId}
      totalCount={filteredDevices.length}
    />
  )
}
